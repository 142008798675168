export const newLife = [
  `<h5>Développeur Freelance
  <small class="company-type">Auto-Entrepreneur</small>
    <small class="company-date">Depuis Juin 2018</small></h5>
  <p class="company-descr">
    Toutes mes compétences, mon énergie et mon talent à votre service...
  </p>

  <dl>
    <dt>Pour Namebay <small class="company-type">Monaco</small></dt>
    <dd>Registrar</dd>
  </dl>`,
  `
  <dl class="level-2">
    <dt>Développement d'une plateforme en marque blanche pour les produits Namebay</dt>
    <dd>
      Web Application permettant l'achat et la gestion de noms de domaines
      Travail en full remote
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux CentOS - PHP 7.2 - Framework
      <a href="http://www.laravel.com" target="_blank">Laravel</a> v6.0 + MariaDB 10.3</dd>
    <dd>Front : <a href="http://www.vuejs.org" target="_blank">VueJS</a>, <a href="http://quasar.dev" target="_blank">Quasar Framework v1</a></dd>
  </dl>
  `
];
