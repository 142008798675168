import { MapService } from '../../../services/map.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { getFRPolygonData } from './polygon-fr.data';
import { Marker, NguiMapComponent, InfoWindow } from '@ngui/map';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarkerData } from '../../../models/markerData';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() selectMarker = new EventEmitter<MarkerData>();
  @Output() infoWindowClosed = new EventEmitter();

  markers: MarkerData[];
  markersSubscription: Subscription;
  mapOptions: Object;
  polygonPath: google.maps.LatLng[][];
  infoWindowData: MarkerData = new MarkerData({
    title: '',
    id: '',
    latLng: { lat: 0, lng: 0 },
    content: ['']
  });
  selectedMarkerId: Observable<string>;

  @ViewChild(NguiMapComponent) nguiMapComponent: NguiMapComponent;

  constructor(
    private mapService: MapService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.mapOptions = this.mapService.mapOptions;
    this.markersSubscription = this.dataService.markersSubject.subscribe(
      (markers: MarkerData[]) => {
        this.markers = markers;
      }
    );
    this.dataService.emitMarkers();
  }

  ngOnDestroy(): void {
    this.markersSubscription.unsubscribe();
  }

  onMarkerClicked(event, data: MarkerData): void {
    const marker: google.maps.Marker = event.target;
    this.infoWindowData = data;
    this.selectMarker.emit(this.infoWindowData);
    this.nguiMapComponent.openInfoWindow('iw', marker);

    marker.setAnimation(google.maps.Animation.BOUNCE);
    setTimeout(() => {
      marker.setAnimation(null);
    }, 1500);

    this.router.navigate(['/cv'], { fragment: data.id });
  }

  onMapReady(gMap: google.maps.Map) {
    this.polygonPath = getFRPolygonData();
    this.mapService.mapReady(gMap);

    // Listen to router changes
    this.activatedRoute.fragment.subscribe((fragment) => {
      this.dataService.getMarkerById(fragment).then(
        (markerData: MarkerData) => {
          google.maps.event.trigger(markerData.googleMarkerObject, 'click');
        },
        (error) => {}
      );
    });
  }

  onMarkerInit(marker: google.maps.Marker, m: MarkerData) {
    this.mapService.initMarker(marker);
    m.googleMarkerObject = marker;
  }

  onDragEnd() {
    this.mapService.limitDrag();
  }

  onInfoWindowClosed() {
    this.infoWindowClosed.emit();
  }
}
