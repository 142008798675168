export const competencies: any[] = [
  {
    name: 'PHP',
    category: 'dev',
    value: 100,
    title: 'PHP 5, PHP 7',
    content:
      "C'est mon language de prédilection, je le pratique depuis près de **15 ans** !"
  },
  {
    name: 'PRADO',
    category: 'dev',
    value: 95,
    title: 'PRADO Framework',
    content: `[PRADO](http://www.pradoframework.net) a été le premier framework sur lequel j'ai travaillé.
    Il s'agit d'un framework PHP évenementiel basé sur des composants.`
  },
  {
    name: 'YII 2.0',
    category: 'dev',
    value: 95,
    content: `Le framework [Yii](http://www.yiiframework.com) a été conçu le développeur à l'origine de PRADO.
    Il n'a cependant rien à voir avec celui-ci, YII est un framework basé sur le modèle MVC.`
  },
  {
    name: 'Symfony',
    category: 'dev',
    value: 80
  },
  {
    name: 'Laravel',
    category: 'dev',
    value: 85
  },
  {
    name: 'Javascript',
    category: 'dev',
    value: 80
  },
  {
    name: 'jQuery',
    category: 'dev',
    value: 75
  },
  {
    name: 'Angular 2+',
    category: 'dev',
    value: 50
  },
  {
    name: 'SQL',
    category: 'dev',
    value: 95
  },
  {
    name: 'noSQL',
    category: 'dev',
    value: 60
  },
  {
    name: 'Environnements Linux',
    category: 'sys',
    value: 100
  },
  {
    name: 'Environnements Windows',
    category: 'sys',
    value: 100
  },
  {
    name: 'Apache',
    category: 'sys',
    value: 95
  },
  {
    name: 'NGINX',
    category: 'sys',
    value: 80
  },
  {
    name: 'Réseaux IP',
    category: 'sys',
    value: 95
  },
  {
    name: 'Docker',
    category: 'sys',
    value: 75
  },
  {
    name: 'VMWare',
    category: 'sys',
    value: 68
  },
  {
    name: 'HTML 5',
    category: 'dev',
    value: 80
  },
  {
    name: 'CSS 3',
    category: 'dev',
    value: 75
  },
  {
    name: 'Bootstrap 3/4',
    category: 'dev',
    value: 90
  },
  {
    name: 'Français',
    category: 'language',
    value: 100
  },
  {
    name: 'Anglais',
    category: 'language',
    value: 80
  },
  {
    name: 'Shell (bash)',
    category: 'sys',
    value: 90
  },
  {
    name: 'Vue JS',
    category: 'dev',
    value: 60
  },
  {
    name: 'Quasar Framework',
    category: 'dev',
    value: 60
  }
];
