import { Component, OnInit, Input } from '@angular/core';
import { MarkerData } from '../../../models/markerData';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../../services/map.service';
import {
  trigger,
  transition,
  stagger,
  animate,
  style,
  query,
  keyframes
} from '@angular/animations';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-cv-details',
  templateUrl: './cv-details.component.html',
  styleUrls: ['./cv-details.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        // each time the binding value changes
        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(
          ':enter',
          stagger('800ms', [
            animate(
              '1s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                style({
                  opacity: 0.5,
                  transform: 'translateY(35px)',
                  offset: 0.3
                }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
              ])
            )
          ]),
          { optional: true }
        )
      ])
    ])
  ]
})
export class CvDetailsComponent implements OnInit {
  @Input()
  details: MarkerData = new MarkerData({
    id: '',
    latLng: { lat: 0, lng: 0 },
    title: '',
    content: ['']
  });
  fromLink = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
  ) {}

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.dataService.getMarkerById(id).then((data) => {
        this.details = data;
        this.fromLink = true;
      });
    }
  }
}
