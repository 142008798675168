export const education = [
  `<h5><a href="http://www.esigetel.fr" target="_blank">ESIGETEL</a><br/>
    <small class="company-type">Ecole Supérieure en Informatique et GEnie des TELécommunications</small></h5>`,
  `<dl><dt>1993 - 1994 : 1<sup>ère</sup> année</dt><dd>Enseignement général</dd></dl>`,
  `<dl><dt>Juillet - Aout 1994 : Stage 1<sup>ère</sup> année</dt>
    <dd><a href="http://www.chru-lille.fr/chrulille/index.html" target="_blank">CHRU Lille</a></dt>
    <dd>Développement en <strong>C</strong> d'un logiciel d'analyse des troubles du sommeil.</dd>
    <dd>Récupération de données provenant de capteurs, Stockage de ces données, Génération de graphes</dd>`,
  `<dl><dt>1994 - 1995 : 2<sup>ème</sup> année</dt><dd>Enseignement général</dd></dl>`,
  `<dl><dt>Juillet - Août 1995 : Stage 2<sup>ème</sup> année</dt>
    <dd><a href="https://www.oracle.com/fr/index.html" target="_blank">Oracle France</a> -- Agence de Villeneuve d'Ascq</dd>
    <dd>Découverte de la base de donnée relationelle du géant américain. Support aux avant-ventes.</dd>
  </dl>`,
  `<dl><dt>1995-1996 : 3<sup>ème</sup> année</dt><dd>Voie d'Approfondissement :
    SAR (Systèmes et Applications Répartis)</dd></dl>`,
  `<dl><dt>Mars - Juin 1996 : Stage 3<sup>ème</sup> année</dt>
    <dd><a href="https://www.inria.fr/" target="_blank">INRIA</a> Rocquencourt</dd>
    <dd>Découverte du monde de la recherche. Etudes sur l'interopérabilité entre les
    <a href="https://en.wikipedia.org/wiki/DCE/RPC" target="_blank">DCE/RPC</a> et
    <a href="https://en.wikipedia.org/wiki/Common_Object_Request_Broker_Architecture" target="_blank">CORBA</a></dd>
   </dl>`,
  `<dl><dt>Juillet 1996 : </dt><dd>Obtention du diplôme d'ingénieur ESIGETEL.</dd></dl>`,
  `<dl><dt>Septembre 1996 :</dt><dd>Entrée dans le <a href="/cv#first-experiences">monde du travail</a>...</dd></dl>`
];
