import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { EmailService } from '../../../services/email.service';
import { environment } from '../../../../environments/environment.prod';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  messageSent = false;
  error: boolean | string = false;

  @ViewChild('status') status: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private mailService: EmailService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      subject: [''],
      message: ['', Validators.required]
    });
  }

  get name() {
    return this.contactForm.get('name');
  }
  get email() {
    return this.contactForm.get('email');
  }
  get phone() {
    return this.contactForm.get('phone');
  }
  get subject() {
    return this.contactForm.get('subject');
  }
  get message() {
    return this.contactForm.get('message');
  }

  onSendMessage() {
    const data = this.contactForm.value;
    const message =
      data.message +
      '\n\n--\n\nMessage envoyé depuis le formulaire de contact\n' +
      '\nNom : ' +
      data.name +
      '\nTél : ' +
      data.phone +
      '\nEmail : ' +
      data.email;
    this.mailService
      .sendEmail({
        from: data.email,
        to: environment.mailContact,
        subject: data.subject,
        message: message
      })
      .subscribe(
        (res: HttpResponse<any>) => {
          const status = res.status;
          if (status === 201) {
            this.contactForm.reset();
            this.messageSent = true;
            setTimeout(() => {
              this.messageSent = false;
              /*this.renderer.addClass(this.status.nativeElement, 'fadeOutDown');*/
            }, 4000);
          }
        },
        (error: HttpErrorResponse) => {
          this.error = error.error ? error.error.errors[0] : true;
          setTimeout(() => {
            this.error = false;
            /*this.renderer.addClass(this.status.nativeElement, 'fadeOutDown');*/
          }, 4000);
        }
      );
  }
}
