import { MarkerIcon } from './markerIcon';

export class MarkerData {
  id: string;
  latLng: google.maps.LatLng | google.maps.LatLngLiteral;
  location: string;
  title: string;
  content: string[];
  startDate?: Date;
  endDate?: Date;
  icon?: MarkerIcon;
  googleMarkerObject: google.maps.Marker;
  private _iconUrl?: string;

  get iconUrl() {
    if (this.icon instanceof MarkerIcon) {
      return this.icon.url;
    } else {
      return this._iconUrl;
    }
  }

  set iconUrl(url) {
    this._iconUrl = url;
  }

  constructor(
    fields: Partial<MarkerData> & {
      id: string;
      latLng: google.maps.LatLng | google.maps.LatLngLiteral;
      title: string;
      content: string[];
    }
  ) {
    Object.assign(this, fields);
  }
}
