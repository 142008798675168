export const frenchRiviera = [
  `<h5>ADD'X Technologies</a>
  <small class="company-type">SSII</small>
    <small class="company-date">Juin. 1999 - Mai 2001</small></h5>`,
  `<dl>
    <dt>Administrateur Systèmes</dt>
    <dd>
      <ul>
        <li>Administration des serveurs et du réseau de l'agence</li>
        <li>Construction du réseau de la nouvelle agence et mise en place d'équipements de sécurité</li>
        <li>Hébergement et infogérance des serveurs clients</li>
        <li>Installation et configuration de firewall pour la CCI Nice Côte d'Azur et les Aéroports de la Côte d'Azur</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
      <dt>Environnement technique :</dt>
      <dd>Serveurs UNIX (HPUX), Linux</dd>
      <dd>Serveurs Windows (NT & 4.0)</dd>
      <dd>Firewall Checkpoint Firewall-1</dd>
  </dl>`,
  `<hr/>`,
  `<h5>CERAM Business School</a>
  <small class="company-type">Ecole de management</small>
    <small class="company-date">Mai 2001 - Sept. 2009</small></h5>
    <p class="company-descr">
      Établissement de la Chambre de Commerce Nice Côte d'Azur, le CERAM possède 2 campus en France (Sophia-Antipolis & Paris).
    </p>`,
  `<dl>
    <dt>Responsable de domaine Systèmes et Réseaux</dt>
    <dd>Trois activités principales : Développement - Systèmes - Réseaux</dd>
  </dl>
  <dl class="level-2">
    <dt>Développement</dt>
    <dd>
      <ul>
        <li>Création d'un outil de gestion des comptes informatiques des étudiants afin d'automatiser les tâches d'exploitation</li>
        <li>Création de la première version de l'intranet des étudiants, basé sur le CMS PHPNuke et des développements spécifiques</li>
        <li>Création des premiers outils de gestion du concours d'entrée à l'école</li>
        <li>Contributions au framework Open-Source <a href="http://www.pradoframework.net/about" target="_blank">PRADO</a></li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
      <dt>Environnement technique :</dt>
      <dd>Architecture LAMP (Linux, Apache, MySQL, PHP)</dd>
      <dd>PHP Version 4 et 5</dd>
      <dd>Framework <a href="http://www.pradoframework.net/about" target="_blank">PRADO v3</a></dd>
      <dd>Javascript client : <a href="http://prototypejs.org/" target="_blank">Prototype JS</a></dd>
  </dl>
  <dl class="level-2">
    <dt>Administration Systèmes</dt>
    <dd>Refonte complète de l'architecture système de l'école avec des technologies Open-Source
      <ul>
        <li>Installation et configuration de serveurs Linux, sur la distribution
          <a href="http://www.gentoo.org" target="_blank">GENTOO Linux</a></li>
        <li>Mise en place d'un domaine émulé "Windows NT" basé sur SAMBA et LDAP</li>
        <li>Installation et mise en place d'un serveur RADIUS (OpenRADIUS)</li>
        <li>Installation et configuration de serveurs WEB (Apache / PHP / MySQL)</li>
        <li>Mise en place d'un service de messagerie mail (PostFix) et antispam (SpamAssassin)</li>
        <li>Mise en place d'une infrastructure de virtualisation VMWare</li>
      </ul>
    </dd>
  </dl>
  <dl class="level-2">
    <dt>Administration Réseaux</dt>
    <dd>Refonte complète de l'architecture réseau de l'école
      <ul>
        <li>Création et mise en place d'équipements réseau redondés</li>
        <li>Segmentation du réseau en VLAN étanches pour séparer le domaine pégadogique de l'administratif</li>
        <li>Installation et configuration de firewall CheckPoint, et création d'une DMZ en vue
          de la publication de sites Web sur Internet
        </li>
        <li>Mise en place de liaison WAN avec les autres établissements de la CCI (Ports, Aéroports, Siège)</li>
        <li>Déploiement WIFI</li>
        <li>Mise en place de l'authentification 802.1X sur les équipements (Filaires & Wifi)</li>
      </ul>
    </dd>
  </dl>`,
  `<dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Serveurs Gentoo Linux</dd>
    <dd>Equipements réseaux 3COM</dd>
    <dd>Firewall CheckPoint Firewall-1</dd>
    <dd>Utilisation de nombreuses technologies OpenSource (Apache, OpenRadius, OpenLDAP, Samba, ...)</a></dd>
</dl>`,

  `<hr/>`,
  `<h5><a href="http://www.skema-bs.fr" target="_blank">SKEMA Business School</a>
  <small class="company-type">Ecole de management</small>
    <small class="company-date">Sept 2009 - Avril 2018</small></h5>
  <p class="company-descr">
      Née de la fusion entre le CERAM Business School et l'École Supérieure de Commerce de Lille,
      SKEMA possède maintenant 6 campus, 3 en France (Lille, Paris & Sophia-Antipolis) et 3 à l'international
      (USA, Chine & Brésil)
    </p>
  <dl>
    <dt>Architecte Systèmes d'Information / Développeur applications WEB</dt>
    <dd>Suite à la fusion, l'architecture du système d'information a du être repensée, les outils ont du évoluer ou être redéveloppés</dd>
  </dl>
  <dl class="level-2">
    <dt>Développement et maintenance du portail étudiant</dt>
    <dd>
      L'ancien intranet étudiant a évolué pour prendre en compte le nouvel outil métier de gestion de la scolarité.
      Ainsi, la base technique de l'intranet étudiants a été conservée, mais de nombreux modules ont été redéveloppés.
      <ul>
        <li>Analyse des besoins métiers, conception et développement complet</li>
        <li>Développement de formulaires complexes (Choix de parcours pour les étudiants
          parmi de nombreuses possibilités, chacune ayant ses propres contraintes)
        </li>
        <li>Création automatique de documents (Certificats de scolarité, attestation de diplôme, ...)</li>
        <li>Mise en place de l'authentification SAML sur Office 365 (SSO)</li>
        <li>Utilisation d'API externes de paiement en ligne Sogenactif (ATOS-SIPS) et Ogone (Ingenico)</li>
        <li>Connexion à l'API SlimPay permettant la signature électronique de mandats de prélèvement</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux Ubuntu Server - PHP 5.5 - Framework
      <a href="http://www.pradoframework.net" target="_blank">PRADO</a> v3.2.4 +
      <a href="http://www.yiiframework.com" target="_blank">YII</a> v2.0 + MySQL 5 + SQL Server</dd>
    <dd>Gestion des sources via GIT (GitLab CE)</dd>
    <dd>Environnement de Développement : Containers Docker</dd>
  </dl>
  <dl class="level-2">
    <dt>Développement et maintenance d'un site web d'inscription au concours d'entrée</dt>
    <dd>
      Suite à la fusion, un nouveau concours d'entrée a été mis en place pour les candidats issus de Licence 3 ou de
      Master 1. Le site permet l'inscription en ligne d'un candidat, le paiement en ligne des frais. Il permet
      également la prise de rendez-vous aux épreuves orales, la consultation des résultats.
      <ul>
        <li>Analyse des besoins métiers, conception et développement complet</li>
        <li>Création de formulaires avec workflow de validation</li>
        <li>Module de prise de rendez-vous sous forte charge (90 % des rendez-vous aux oraux, soit environ 2000
          rdv sont pris dans la 1ère heure)</li>
        <li>Optimisation des configurations serveurs (hard & soft) pour absorber de forts pics de connexions lors de
        l'affichage des résultats</li>
        <li>Utilisation d'API externes de paiement en ligne Sogenactif (ATOS-SIPS) et Ogone (Ingenico)</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux Ubuntu Server - PHP 5.5 - Framework
      <a href="http://www.pradoframework.net" target="_blank">PRADO</a> v3.3 + MySQL 5</dd>
    <dd>Gestion des sources via GIT (GitLab CE)</dd>
    <dd>Environnement de Développement : Containers Docker</dd>
  </dl>
  <dl class="level-2">
    <dt>Développement et maintenance d’un site web d’inscription et de gestion des examinateurs des concours</dt>
    <dd>
      Ce site permet aux personnes souhaitant participer aux jurys d'entretien du concours de s'inscrire en ligne et
      de donner leurs disponibilités sur la période du concours. Un Back Office permet alors de planifier les jurys
      <ul>
        <li>Analyse des besoins métiers, conception et développement complet</li>
        <li>Création visuelle d'un planning par drag'n'drop</li>
        <li>Envoi d'email automatiques aux inscrits</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux Ubuntu Server - PHP 7.0 - Framework
      <a href="http://www.yiiframework.com" target="_blank">YII</a> v2.0+ MariaDB 10.0 + JQuery</dd>
    <dd>Gestion des sources via GIT (GitLab CE)</dd>
    <dd>Environnement de Développement : Containers Docker</dd>
  </dl>
  <dl class="level-2">
    <dt>Infrastructure Systèmes & Réseaux</dt>
    <dd>
      <ul>
        <li>Fusion des Systèmes d'Information de l'ESC Lille (100% Windows) et du CERAM (90% Linux),
        séparation avec la CCI Nice</li>
        <li>Mise en place d'un domaine Active Directory commun (fusion de 3 domaines indépendants :
          1 domaine NT/SAMBA, 1 domaine AD de l'ESC-Lille, 1 domaine AD de la CCI)</li>
        <li>Mise en place de Microsoft Live@Edu, puis Office 365 pour l'éducation</li>
        <li>Mise en place de tunnels VPN IPSec entre les différents campus de SKEMA</li>
        <li>Externalisation de certains services dans Microsoft Azure en mode IAAS ou SAAS</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Serveurs Microsoft Windows 2008 à 2012, Serveurs Linux Ubuntu & Gentoo</dd>
    <dd>Cloud Microsoft Azure</dd>
    <dd>Infrasctucture VMWare</dd>
    <dd>Firewalls Fortinet, Equipement réseau HP/Cisco</dd>
  </dl>
  <dl class="level-2">
    <dt>Architecture Systèmes d'Information</dt>
    <dd>
      <ul>
        <li>Cartographie des applications par domaine fonctionnels </li>
        <li>Etude d'optimisation des flux inter-applications via un ESB (Produits étudiés :
          Microsoft Logic Apps, WSO2 ESB, Talend Integration Platform)</li>
        <li>Pilotage du projet "Interfaces" dans le cadre du changement d'ERP (Oracle PeopleSoft Campus Solutions)</li>
        <li>Création de "Logic Apps" afin de faciliter certains flux d'échange</li>
      </ul>
    </dd>
  </dl>
  `,
  `<hr/>`,
  `<h5>Développeur Freelance
  <small class="company-type">Auto-Entrepreneur</small>
    <small class="company-date">Depuis Juin 2018</small></h5>
  <p class="company-descr">
    Toutes mes compétences, mon énergie et mon talent à votre service...
  </p>
  <dl>
    <dt>Pour la CCI Nice Côte d'Azur</dt>
    <dd></dd>
  </dl>
  <dl class="level-2">
    <dt>Audit des sites <a href="http://sophiaantipolis-careers.com" target="_blank">Sophia Antipolis Careers</a>
          et <a href="http://emploisindustrie06.com" target="_blank">Emploi Industrie 06</a></dt>
    <dd>
      Audit du code source des sites en prévision de l'ouverture d'un nouveau site sur le même modèle
      <ul>
          <li>Analyse de l'existant</li>
          <li>Analyse du code source</li>
          <li>Préconisation pour l'ouverture d'un nouveau site</li>
        </ul>
    </dd>

    <dt>Développement du nouveau portail "<a href="https://emploibtp06.com" target="_blank">Emploi BTP 06"</a></dt>
    <dd>
      Mise en relation entre demandeurs d'emploi et recruteurs dans le secteur du BTP sur la Côte d'Azur, sur le même
      modèle que les sites <a href="http://sophiaantipolis-careers.com" target="_blank">Sophia Antipolis Careers</a>
      et <a href="http://emploisindustrie06.com" target="_blank">Emploi Industrie 06</a>
      <ul>
        <li>Analyse des besoins métiers</li>
        <li>Connexion API externes d'un fournisseur d'offre d'emploi</li>
        <li>Refonte complète UI</li>
        <li>Mise à jour du code pour compatibilité PHP 7</li>

      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux Debian - PHP 7.1 - Framework
      <a href="http://www.yiiframework.com" target="_blank">YII</a> v1.1 + MySQL 5.7</dd>
    <dd>Front : Bootstrap v4.1, jQuery</dd>
  </dl>`,
  `
  <dl>
    <dt>Pour un client <small><i>(confidentiel)</i></small></dt>
    <dd></dd>
  </dl>
  <dl class="level-2">
    <dt>Réalisation d'un site de petites annonces internationales</dt>
    <dd>
      Développement d'un site web de petites annonces avec mise en relation acheteur/vendeur par visioconférence
      <ul>
        <li>Import d'annonces de fournisseurs tiers</li>
        <li>Mise en place visio conférence via WebRTC</li>
        <li>Développement d'un "Thésaurus" pour traduction des annonces en 13 langues</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux Debian - PHP 7.1 - Framework
      <a href="http://www.symfony.com" target="_blank">Symfony</a> v4.1 + MySQL 5.7</dd>
    <dd>Front : Bootstrap v3.3.4, jQuery</dd>
  </dl>
  `,
  `
  <dl>
    <dt>Pour Namebay <small class="company-type">Registrar</small></dt>
    <dd>Registrar</dd>
  </dl>`,
  /*
  <dl class="level-2">
    <dt>Corrections et poursuite du développement d'un portail de gestion de noms de domaines</dt>
    <dd>
      <ul>
        <li>Import d'annonces de fournisseurs tiers</li>
        <li>Mise en place visio conférence via WebRTC</li>
        <li>Développement d'un "Thésaurus" pour traduction des annonces en 13 langues</li>
      </ul>
    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux Debian - PHP 7.1 - Framework
      <a href="http://www.symfony.com" target="_blank">Symfony</a> v3.4 + MySQL 5.7</dd>
    <dd>Front : MaterializeCss, jQuery</dd>
  </dl>
  */
  `
  <dl class="level-2">
    <dt>Développement d'une plateforme en marque blanche pour les produits Namebay</dt>
    <dd>

    </dd>
  </dl>
  <dl class="tech-env">
    <dt>Environnement technique :</dt>
    <dd>Production: Linux CentOS - PHP 7.2 - Framework
      <a href="http://www.laravel.com" target="_blank">Laravel</a> v5.8 + MySQL 5.7</dd>
    <dd>Front : <a href="http://www.vuejs.org" target="_blank">VueJS</a>, <a href="http://quasar.dev" target="_blank">Quasar Framework v1</a></dd>
  </dl>
  `
];
