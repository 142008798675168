/**
* Generated with http://maps.vasile.ch/geomask/
*/
export function getFRPolygonData(): google.maps.LatLng[][] { return [
 [
    new google.maps.LatLng(-85.1054596961173, -180),
    new google.maps.LatLng(85.1054596961173, -180),
    new google.maps.LatLng(85.1054596961173, 180),
    new google.maps.LatLng(-85.1054596961173, 180),
    new google.maps.LatLng(-85.1054596961173, 0)
  ], [
    new google.maps.LatLng(47.503029, 7.130341),
    new google.maps.LatLng(47.493555, 7.200891),
    new google.maps.LatLng(47.447182, 7.173074),
    new google.maps.LatLng(47.436930, 7.235768),
    new google.maps.LatLng(47.422198, 7.246298),
    new google.maps.LatLng(47.437814, 7.301736),
    new google.maps.LatLng(47.432691, 7.387798),
    new google.maps.LatLng(47.470497, 7.455660),
    new google.maps.LatLng(47.480171, 7.421223),
    new google.maps.LatLng(47.496064, 7.431853),
    new google.maps.LatLng(47.482553, 7.488665),
    new google.maps.LatLng(47.504115, 7.510509),
    new google.maps.LatLng(47.521223, 7.497802),
    new google.maps.LatLng(47.514242, 7.522937),
    new google.maps.LatLng(47.527425, 7.531476),
    new google.maps.LatLng(47.528725, 7.501392),
    new google.maps.LatLng(47.543451, 7.504686),
    new google.maps.LatLng(47.577266, 7.584707),
    new google.maps.LatLng(47.603022, 7.592252),
    new google.maps.LatLng(47.662322, 7.522340),
    new google.maps.LatLng(47.697041, 7.511852),
    new google.maps.LatLng(47.729918, 7.547662),
    new google.maps.LatLng(47.778902, 7.529620),
    new google.maps.LatLng(47.840802, 7.562344),
    new google.maps.LatLng(47.879941, 7.556707),
    new google.maps.LatLng(47.972272, 7.622086),
    new google.maps.LatLng(48.034112, 7.568699),
    new google.maps.LatLng(48.120371, 7.577316),
    new google.maps.LatLng(48.221840, 7.666654),
    new google.maps.LatLng(48.301526, 7.693377),
    new google.maps.LatLng(48.326294, 7.743953),
    new google.maps.LatLng(48.395309, 7.732514),
    new google.maps.LatLng(48.489005, 7.767784),
    new google.maps.LatLng(48.513573, 7.805201),
    new google.maps.LatLng(48.584542, 7.800404),
    new google.maps.LatLng(48.641377, 7.839822),
    new google.maps.LatLng(48.721356, 7.963144),
    new google.maps.LatLng(48.757632, 7.971752),
    new google.maps.LatLng(48.760654, 8.013488),
    new google.maps.LatLng(48.787320, 8.030387),
    new google.maps.LatLng(48.805704, 8.091547),
    new google.maps.LatLng(48.896065, 8.141561),
    new google.maps.LatLng(48.974093, 8.226822),
    new google.maps.LatLng(48.967224, 8.199089),
    new google.maps.LatLng(48.989260, 8.091380),
    new google.maps.LatLng(49.014173, 8.048084),
    new google.maps.LatLng(49.027746, 7.974852),
    new google.maps.LatLng(49.057811, 7.934623),
    new google.maps.LatLng(49.040966, 7.916698),
    new google.maps.LatLng(49.047694, 7.886764),
    new google.maps.LatLng(49.033386, 7.866647),
    new google.maps.LatLng(49.064115, 7.800442),
    new google.maps.LatLng(49.043927, 7.735740),
    new google.maps.LatLng(49.055406, 7.730268),
    new google.maps.LatLng(49.045042, 7.674489),
    new google.maps.LatLng(49.054167, 7.635213),
    new google.maps.LatLng(49.081934, 7.604847),
    new google.maps.LatLng(49.092068, 7.539006),
    new google.maps.LatLng(49.136530, 7.489578),
    new google.maps.LatLng(49.152904, 7.506194),
    new google.maps.LatLng(49.169804, 7.491193),
    new google.maps.LatLng(49.164821, 7.434315),
    new google.maps.LatLng(49.184305, 7.445065),
    new google.maps.LatLng(49.172468, 7.366098),
    new google.maps.LatLng(49.145216, 7.362847),
    new google.maps.LatLng(49.144154, 7.327362),
    new google.maps.LatLng(49.115102, 7.293744),
    new google.maps.LatLng(49.127982, 7.247792),
    new google.maps.LatLng(49.115153, 7.197991),
    new google.maps.LatLng(49.130727, 7.183939),
    new google.maps.LatLng(49.120773, 7.158800),
    new google.maps.LatLng(49.141868, 7.126254),
    new google.maps.LatLng(49.138687, 7.104239),
    new google.maps.LatLng(49.155625, 7.100935),
    new google.maps.LatLng(49.112636, 7.054302),
    new google.maps.LatLng(49.189681, 7.034325),
    new google.maps.LatLng(49.209760, 6.974972),
    new google.maps.LatLng(49.203005, 6.959281),
    new google.maps.LatLng(49.222156, 6.935419),
    new google.maps.LatLng(49.212906, 6.837436),
    new google.maps.LatLng(49.178714, 6.860947),
    new google.maps.LatLng(49.151380, 6.834467),
    new google.maps.LatLng(49.168312, 6.783101),
    new google.maps.LatLng(49.163661, 6.738508),
    new google.maps.LatLng(49.188538, 6.711363),
    new google.maps.LatLng(49.219054, 6.723334),
    new google.maps.LatLng(49.215907, 6.694803),
    new google.maps.LatLng(49.249687, 6.689767),
    new google.maps.LatLng(49.257332, 6.661530),
    new google.maps.LatLng(49.280602, 6.669186),
    new google.maps.LatLng(49.347353, 6.565843),
    new google.maps.LatLng(49.366781, 6.601856),
    new google.maps.LatLng(49.401146, 6.540524),
    new google.maps.LatLng(49.426798, 6.548380),
    new google.maps.LatLng(49.476400, 6.430120),
    new google.maps.LatLng(49.469427, 6.367677),
    new google.maps.LatLng(49.459821, 6.368853),
    new google.maps.LatLng(49.466890, 6.333778),
    new google.maps.LatLng(49.510019, 6.256410),
    new google.maps.LatLng(49.504112, 6.155761),
    new google.maps.LatLng(49.470225, 6.102078),
    new google.maps.LatLng(49.452882, 6.100816),
    new google.maps.LatLng(49.465362, 6.055622),
    new google.maps.LatLng(49.447803, 6.042155),
    new google.maps.LatLng(49.451067, 5.982048),
    new google.maps.LatLng(49.491122, 5.972382),
    new google.maps.LatLng(49.498778, 5.869087),
    new google.maps.LatLng(49.519954, 5.836039),
    new google.maps.LatLng(49.542475, 5.836959),
    new google.maps.LatLng(49.537000, 5.817064),
    new google.maps.LatLng(49.563246, 5.771459),
    new google.maps.LatLng(49.539430, 5.747381),
    new google.maps.LatLng(49.547900, 5.643326),
    new google.maps.LatLng(49.505927, 5.610987),
    new google.maps.LatLng(49.529251, 5.556050),
    new google.maps.LatLng(49.497209, 5.470905),
    new google.maps.LatLng(49.517878, 5.447195),
    new google.maps.LatLng(49.536765, 5.465899),
    new google.maps.LatLng(49.550822, 5.441962),
    new google.maps.LatLng(49.564921, 5.453553),
    new google.maps.LatLng(49.596649, 5.427770),
    new google.maps.LatLng(49.631290, 5.347939),
    new google.maps.LatLng(49.611474, 5.314623),
    new google.maps.LatLng(49.630053, 5.304918),
    new google.maps.LatLng(49.654234, 5.332448),
    new google.maps.LatLng(49.696072, 5.269185),
    new google.maps.LatLng(49.685698, 5.243600),
    new google.maps.LatLng(49.693026, 5.165307),
    new google.maps.LatLng(49.714805, 5.161569),
    new google.maps.LatLng(49.717306, 5.123070),
    new google.maps.LatLng(49.763727, 5.093500),
    new google.maps.LatLng(49.780083, 5.013549),
    new google.maps.LatLng(49.799311, 4.998835),
    new google.maps.LatLng(49.801069, 4.955218),
    new google.maps.LatLng(49.786852, 4.930928),
    new google.maps.LatLng(49.792362, 4.855377),
    new google.maps.LatLng(49.820796, 4.873879),
    new google.maps.LatLng(49.861022, 4.851765),
    new google.maps.LatLng(49.906258, 4.887667),
    new google.maps.LatLng(49.948410, 4.848125),
    new google.maps.LatLng(49.968945, 4.789917),
    new google.maps.LatLng(50.039055, 4.841163),
    new google.maps.LatLng(50.065877, 4.819311),
    new google.maps.LatLng(50.067107, 4.837935),
    new google.maps.LatLng(50.101328, 4.850881),
    new google.maps.LatLng(50.087732, 4.872134),
    new google.maps.LatLng(50.124646, 4.870636),
    new google.maps.LatLng(50.137791, 4.897075),
    new google.maps.LatLng(50.168317, 4.822582),
    new google.maps.LatLng(50.084731, 4.692952),
    new google.maps.LatLng(49.995520, 4.693577),
    new google.maps.LatLng(49.982782, 4.581389),
    new google.maps.LatLng(49.946802, 4.511319),
    new google.maps.LatLng(49.937169, 4.445797),
    new google.maps.LatLng(49.966176, 4.301483),
    new google.maps.LatLng(49.954570, 4.197426),
    new google.maps.LatLng(49.967724, 4.197114),
    new google.maps.LatLng(49.978452, 4.141163),
    new google.maps.LatLng(49.995449, 4.163149),
    new google.maps.LatLng(50.019392, 4.135778),
    new google.maps.LatLng(50.048982, 4.162224),
    new google.maps.LatLng(50.073338, 4.230783),
    new google.maps.LatLng(50.131081, 4.199887),
    new google.maps.LatLng(50.134919, 4.126918),
    new google.maps.LatLng(50.166770, 4.155121),
    new google.maps.LatLng(50.213419, 4.150860),
    new google.maps.LatLng(50.252205, 4.220693),
    new google.maps.LatLng(50.272965, 4.207533),
    new google.maps.LatLng(50.288920, 4.162674),
    new google.maps.LatLng(50.275589, 4.149403),
    new google.maps.LatLng(50.259699, 4.167608),
    new google.maps.LatLng(50.257187, 4.135381),
    new google.maps.LatLng(50.300056, 4.119960),
    new google.maps.LatLng(50.309240, 4.079536),
    new google.maps.LatLng(50.358563, 4.023485),
    new google.maps.LatLng(50.327061, 3.886058),
    new google.maps.LatLng(50.353105, 3.847401),
    new google.maps.LatLng(50.350935, 3.746845),
    new google.maps.LatLng(50.303170, 3.710431),
    new google.maps.LatLng(50.370784, 3.657204),
    new google.maps.LatLng(50.453174, 3.664218),
    new google.maps.LatLng(50.492471, 3.613017),
    new google.maps.LatLng(50.500612, 3.567768),
    new google.maps.LatLng(50.487265, 3.500774),
    new google.maps.LatLng(50.522872, 3.519405),
    new google.maps.LatLng(50.533564, 3.473964),
    new google.maps.LatLng(50.506604, 3.449151),
    new google.maps.LatLng(50.491081, 3.376215),
    new google.maps.LatLng(50.527578, 3.286524),
    new google.maps.LatLng(50.657785, 3.241102),
    new google.maps.LatLng(50.700647, 3.258377),
    new google.maps.LatLng(50.724409, 3.191227),
    new google.maps.LatLng(50.790127, 3.150783),
    new google.maps.LatLng(50.794122, 3.110931),
    new google.maps.LatLng(50.773371, 3.088699),
    new google.maps.LatLng(50.780108, 3.053493),
    new google.maps.LatLng(50.751954, 2.951690),
    new google.maps.LatLng(50.692320, 2.906066),
    new google.maps.LatLng(50.722915, 2.848386),
    new google.maps.LatLng(50.724609, 2.795656),
    new google.maps.LatLng(50.808632, 2.725640),
    new google.maps.LatLng(50.822462, 2.669309),
    new google.maps.LatLng(50.812756, 2.634976),
    new google.maps.LatLng(50.850216, 2.599063),
    new google.maps.LatLng(50.910075, 2.607945),
    new google.maps.LatLng(50.919097, 2.590009),
    new google.maps.LatLng(50.946009, 2.632680),
    new google.maps.LatLng(51.003298, 2.574068),
    new google.maps.LatLng(51.089001, 2.545542),
    new google.maps.LatLng(51.050332, 2.395233),
    new google.maps.LatLng(51.059830, 2.347833),
    new google.maps.LatLng(51.031682, 2.214001),
    new google.maps.LatLng(51.044382, 2.165933),
    new google.maps.LatLng(51.036557, 2.195180),
    new google.maps.LatLng(51.022590, 2.175731),
    new google.maps.LatLng(51.018264, 2.190455),
    new google.maps.LatLng(51.006790, 2.177389),
    new google.maps.LatLng(51.034245, 2.148650),
    new google.maps.LatLng(51.005152, 2.110099),
    new google.maps.LatLng(50.986119, 2.120583),
    new google.maps.LatLng(51.008606, 2.083935),
    new google.maps.LatLng(50.970956, 1.852701),
    new google.maps.LatLng(50.941123, 1.736849),
    new google.maps.LatLng(50.881232, 1.646545),
    new google.maps.LatLng(50.869885, 1.584398),
    new google.maps.LatLng(50.803762, 1.610641),
    new google.maps.LatLng(50.723707, 1.601309),
    new google.maps.LatLng(50.724986, 1.562489),
    new google.maps.LatLng(50.572223, 1.576604),
    new google.maps.LatLng(50.517448, 1.629886),
    new google.maps.LatLng(50.506474, 1.662804),
    new google.maps.LatLng(50.536178, 1.584160),
    new google.maps.LatLng(50.404714, 1.557795),
    new google.maps.LatLng(50.352149, 1.641540),
    new google.maps.LatLng(50.366296, 1.625183),
    new google.maps.LatLng(50.361316, 1.555830),
    new google.maps.LatLng(50.281456, 1.537892),
    new google.maps.LatLng(50.259645, 1.549954),
    new google.maps.LatLng(50.256455, 1.592226),
    new google.maps.LatLng(50.215152, 1.622501),
    new google.maps.LatLng(50.213594, 1.662666),
    new google.maps.LatLng(50.180776, 1.681617),
    new google.maps.LatLng(50.185502, 1.596192),
    new google.maps.LatLng(50.214482, 1.545791),
    new google.maps.LatLng(50.184159, 1.491677),
    new google.maps.LatLng(50.104896, 1.448396),
    new google.maps.LatLng(49.968034, 1.194515),
    new google.maps.LatLng(49.916487, 1.025394),
    new google.maps.LatLng(49.919502, 0.959298),
    new google.maps.LatLng(49.875749, 0.790972),
    new google.maps.LatLng(49.851811, 0.580436),
    new google.maps.LatLng(49.747890, 0.338895),
    new google.maps.LatLng(49.704796, 0.189585),
    new google.maps.LatLng(49.510067, 0.066548),
    new google.maps.LatLng(49.463194, 0.121240),
    new google.maps.LatLng(49.449872, 0.339315),
    new google.maps.LatLng(49.434626, 0.340878),
    new google.maps.LatLng(49.426761, 0.219941),
    new google.maps.LatLng(49.404890, 0.135656),
    new google.maps.LatLng(49.332563, 0.014302),
    new google.maps.LatLng(49.297907, -0.087163),
    new google.maps.LatLng(49.281820, -0.225695),
    new google.maps.LatLng(49.333003, -0.396611),
    new google.maps.LatLng(49.353852, -0.795692),
    new google.maps.LatLng(49.396509, -0.962026),
    new google.maps.LatLng(49.389963, -1.072667),
    new google.maps.LatLng(49.351509, -1.115396),
    new google.maps.LatLng(49.354043, -1.136049),
    new google.maps.LatLng(49.364897, -1.177540),
    new google.maps.LatLng(49.408032, -1.167998),
    new google.maps.LatLng(49.538928, -1.306360),
    new google.maps.LatLng(49.583173, -1.296228),
    new google.maps.LatLng(49.583823, -1.269751),
    new google.maps.LatLng(49.571359, -1.274292),
    new google.maps.LatLng(49.612307, -1.255507),
    new google.maps.LatLng(49.607863, -1.228866),
    new google.maps.LatLng(49.694943, -1.266585),
    new google.maps.LatLng(49.706957, -1.373176),
    new google.maps.LatLng(49.697007, -1.473257),
    new google.maps.LatLng(49.668515, -1.486920),
    new google.maps.LatLng(49.656717, -1.605205),
    new google.maps.LatLng(49.643458, -1.620493),
    new google.maps.LatLng(49.659676, -1.629369),
    new google.maps.LatLng(49.658172, -1.615442),
    new google.maps.LatLng(49.688872, -1.815242),
    new google.maps.LatLng(49.716941, -1.852483),
    new google.maps.LatLng(49.707153, -1.890118),
    new google.maps.LatLng(49.725558, -1.942247),
    new google.maps.LatLng(49.674760, -1.947120),
    new google.maps.LatLng(49.657683, -1.873560),
    new google.maps.LatLng(49.626831, -1.847754),
    new google.maps.LatLng(49.571733, -1.841026),
    new google.maps.LatLng(49.535088, -1.887865),
    new google.maps.LatLng(49.503749, -1.848163),
    new google.maps.LatLng(49.373460, -1.809348),
    new google.maps.LatLng(49.380014, -1.771555),
    new google.maps.LatLng(49.368190, -1.756916),
    new google.maps.LatLng(49.374589, -1.787101),
    new google.maps.LatLng(49.327108, -1.724282),
    new google.maps.LatLng(49.330343, -1.680232),
    new google.maps.LatLng(49.325095, -1.711172),
    new google.maps.LatLng(49.289877, -1.687066),
    new google.maps.LatLng(49.281819, -1.657366),
    new google.maps.LatLng(49.280721, -1.678415),
    new google.maps.LatLng(49.211045, -1.627100),
    new google.maps.LatLng(49.232500, -1.613501),
    new google.maps.LatLng(49.220881, -1.567265),
    new google.maps.LatLng(49.214491, -1.610749),
    new google.maps.LatLng(49.131476, -1.580302),
    new google.maps.LatLng(49.102328, -1.609535),
    new google.maps.LatLng(49.037370, -1.598876),
    new google.maps.LatLng(49.001304, -1.577941),
    new google.maps.LatLng(49.038252, -1.559915),
    new google.maps.LatLng(49.020131, -1.504676),
    new google.maps.LatLng(49.034067, -1.534516),
    new google.maps.LatLng(49.024690, -1.556148),
    new google.maps.LatLng(48.894066, -1.549963),
    new google.maps.LatLng(48.934232, -1.552489),
    new google.maps.LatLng(48.852192, -1.582781),
    new google.maps.LatLng(48.834218, -1.614437),
    new google.maps.LatLng(48.821682, -1.574438),
    new google.maps.LatLng(48.744223, -1.571012),
    new google.maps.LatLng(48.731023, -1.531928),
    new google.maps.LatLng(48.687407, -1.504951),
    new google.maps.LatLng(48.670436, -1.449797),
    new google.maps.LatLng(48.655011, -1.445306),
    new google.maps.LatLng(48.693750, -1.372085),
    new google.maps.LatLng(48.658193, -1.400832),
    new google.maps.LatLng(48.631441, -1.347349),
    new google.maps.LatLng(48.643170, -1.408675),
    new google.maps.LatLng(48.613204, -1.511935),
    new google.maps.LatLng(48.631216, -1.543545),
    new google.maps.LatLng(48.602391, -1.767710),
    new google.maps.LatLng(48.613703, -1.837850),
    new google.maps.LatLng(48.635297, -1.866622),
    new google.maps.LatLng(48.666577, -1.863348),
    new google.maps.LatLng(48.680296, -1.835802),
    new google.maps.LatLng(48.709324, -1.844412),
    new google.maps.LatLng(48.690683, -1.903066),
    new google.maps.LatLng(48.702521, -1.936929),
    new google.maps.LatLng(48.690545, -1.959439),
    new google.maps.LatLng(48.685720, -1.942551),
    new google.maps.LatLng(48.684133, -1.988479),
    new google.maps.LatLng(48.664576, -1.988824),
    new google.maps.LatLng(48.647911, -2.028903),
    new google.maps.LatLng(48.613056, -2.002394),
    new google.maps.LatLng(48.599902, -2.012305),
    new google.maps.LatLng(48.592203, -1.961438),
    new google.maps.LatLng(48.576469, -1.954660),
    new google.maps.LatLng(48.583935, -1.986999),
    new google.maps.LatLng(48.536556, -1.969404),
    new google.maps.LatLng(48.539028, -1.948188),
    new google.maps.LatLng(48.520082, -1.942484),
    new google.maps.LatLng(48.526327, -1.964725),
    new google.maps.LatLng(48.498461, -1.976353),
    new google.maps.LatLng(48.491571, -2.001033),
    new google.maps.LatLng(48.536840, -1.972478),
    new google.maps.LatLng(48.636979, -2.048692),
    new google.maps.LatLng(48.633312, -2.150281),
    new google.maps.LatLng(48.604406, -2.123706),
    new google.maps.LatLng(48.618145, -2.149938),
    new google.maps.LatLng(48.577201, -2.167105),
    new google.maps.LatLng(48.581332, -2.186746),
    new google.maps.LatLng(48.610379, -2.195909),
    new google.maps.LatLng(48.572759, -2.212854),
    new google.maps.LatLng(48.634948, -2.255839),
    new google.maps.LatLng(48.645626, -2.245871),
    new google.maps.LatLng(48.613164, -2.313473),
    new google.maps.LatLng(48.620026, -2.337833),
    new google.maps.LatLng(48.664291, -2.285535),
    new google.maps.LatLng(48.688638, -2.317998),
    new google.maps.LatLng(48.633089, -2.417094),
    new google.maps.LatLng(48.652183, -2.435630),
    new google.maps.LatLng(48.644351, -2.485397),
    new google.maps.LatLng(48.622520, -2.473431),
    new google.maps.LatLng(48.598731, -2.550274),
    new google.maps.LatLng(48.526089, -2.630281),
    new google.maps.LatLng(48.531129, -2.681560),
    new google.maps.LatLng(48.491700, -2.681569),
    new google.maps.LatLng(48.532845, -2.720984),
    new google.maps.LatLng(48.555376, -2.717974),
    new google.maps.LatLng(48.596245, -2.823214),
    new google.maps.LatLng(48.654977, -2.830917),
    new google.maps.LatLng(48.721453, -2.945728),
    new google.maps.LatLng(48.754427, -2.928635),
    new google.maps.LatLng(48.771591, -2.951257),
    new google.maps.LatLng(48.762639, -2.989506),
    new google.maps.LatLng(48.775914, -3.029395),
    new google.maps.LatLng(48.782143, -3.009940),
    new google.maps.LatLng(48.785135, -3.046008),
    new google.maps.LatLng(48.802627, -3.003760),
    new google.maps.LatLng(48.821452, -3.011853),
    new google.maps.LatLng(48.820900, -3.070432),
    new google.maps.LatLng(48.768296, -3.099637),
    new google.maps.LatLng(48.760413, -3.123483),
    new google.maps.LatLng(48.830434, -3.071776),
    new google.maps.LatLng(48.830605, -3.099420),
    new google.maps.LatLng(48.860686, -3.093498),
    new google.maps.LatLng(48.857738, -3.075959),
    new google.maps.LatLng(48.866790, -3.101676),
    new google.maps.LatLng(48.883666, -3.072328),
    new google.maps.LatLng(48.853335, -3.167773),
    new google.maps.LatLng(48.783969, -3.220388),
    new google.maps.LatLng(48.789548, -3.237255),
    new google.maps.LatLng(48.834507, -3.202827),
    new google.maps.LatLng(48.833838, -3.219284),
    new google.maps.LatLng(48.867884, -3.224262),
    new google.maps.LatLng(48.834201, -3.264335),
    new google.maps.LatLng(48.838017, -3.321315),
    new google.maps.LatLng(48.797250, -3.430190),
    new google.maps.LatLng(48.820626, -3.428908),
    new google.maps.LatLng(48.816084, -3.458937),
    new google.maps.LatLng(48.837216, -3.478682),
    new google.maps.LatLng(48.824302, -3.502851),
    new google.maps.LatLng(48.838702, -3.503519),
    new google.maps.LatLng(48.814440, -3.547170),
    new google.maps.LatLng(48.802711, -3.535039),
    new google.maps.LatLng(48.788498, -3.582269),
    new google.maps.LatLng(48.758271, -3.582426),
    new google.maps.LatLng(48.745686, -3.549404),
    new google.maps.LatLng(48.717143, -3.584814),
    new google.maps.LatLng(48.674724, -3.573228),
    new google.maps.LatLng(48.669664, -3.609671),
    new google.maps.LatLng(48.685932, -3.624529),
    new google.maps.LatLng(48.659137, -3.658802),
    new google.maps.LatLng(48.696295, -3.643659),
    new google.maps.LatLng(48.687122, -3.682401),
    new google.maps.LatLng(48.718536, -3.820099),
    new google.maps.LatLng(48.701352, -3.820772),
    new google.maps.LatLng(48.711936, -3.832374),
    new google.maps.LatLng(48.686374, -3.854474),
    new google.maps.LatLng(48.660181, -3.846259),
    new google.maps.LatLng(48.670914, -3.864485),
    new google.maps.LatLng(48.629004, -3.847079),
    new google.maps.LatLng(48.628921, -3.811282),
    new google.maps.LatLng(48.626761, -3.845983),
    new google.maps.LatLng(48.603679, -3.849928),
    new google.maps.LatLng(48.603991, -3.870646),
    new google.maps.LatLng(48.629546, -3.858584),
    new google.maps.LatLng(48.657936, -3.905361),
    new google.maps.LatLng(48.670833, -3.890764),
    new google.maps.LatLng(48.674928, -3.921181),
    new google.maps.LatLng(48.650211, -3.951317),
    new google.maps.LatLng(48.602153, -3.940830),
    new google.maps.LatLng(48.722709, -3.967400),
    new google.maps.LatLng(48.727374, -3.987124),
    new google.maps.LatLng(48.711632, -4.000536),
    new google.maps.LatLng(48.727047, -4.006522),
    new google.maps.LatLng(48.701049, -4.056519),
    new google.maps.LatLng(48.687546, -4.056750),
    new google.maps.LatLng(48.687428, -4.035678),
    new google.maps.LatLng(48.685888, -4.065293),
    new google.maps.LatLng(48.669615, -4.057117),
    new google.maps.LatLng(48.692114, -4.080512),
    new google.maps.LatLng(48.694927, -4.127037),
    new google.maps.LatLng(48.686462, -4.186934),
    new google.maps.LatLng(48.658244, -4.222277),
    new google.maps.LatLng(48.651996, -4.194479),
    new google.maps.LatLng(48.649580, -4.272766),
    new google.maps.LatLng(48.632641, -4.298511),
    new google.maps.LatLng(48.639035, -4.316520),
    new google.maps.LatLng(48.662937, -4.297259),
    new google.maps.LatLng(48.676941, -4.341224),
    new google.maps.LatLng(48.655380, -4.435353),
    new google.maps.LatLng(48.636131, -4.397805),
    new google.maps.LatLng(48.638394, -4.446944),
    new google.maps.LatLng(48.621186, -4.501804),
    new google.maps.LatLng(48.637034, -4.542202),
    new google.maps.LatLng(48.609074, -4.568288),
    new google.maps.LatLng(48.572079, -4.478406),
    new google.maps.LatLng(48.597492, -4.541908),
    new google.maps.LatLng(48.593288, -4.595305),
    new google.maps.LatLng(48.608201, -4.597389),
    new google.maps.LatLng(48.575614, -4.609663),
    new google.maps.LatLng(48.550585, -4.508375),
    new google.maps.LatLng(48.553404, -4.595551),
    new google.maps.LatLng(48.570438, -4.596081),
    new google.maps.LatLng(48.577693, -4.630607),
    new google.maps.LatLng(48.570031, -4.704411),
    new google.maps.LatLng(48.550048, -4.704112),
    new google.maps.LatLng(48.556346, -4.730581),
    new google.maps.LatLng(48.516161, -4.776856),
    new google.maps.LatLng(48.472098, -4.762527),
    new google.maps.LatLng(48.473963, -4.718376),
    new google.maps.LatLng(48.466024, -4.765047),
    new google.maps.LatLng(48.416236, -4.793343),
    new google.maps.LatLng(48.378357, -4.760627),
    new google.maps.LatLng(48.362041, -4.789543),
    new google.maps.LatLng(48.366808, -4.746316),
    new google.maps.LatLng(48.358592, -4.780718),
    new google.maps.LatLng(48.327951, -4.760651),
    new google.maps.LatLng(48.332259, -4.707608),
    new google.maps.LatLng(48.355611, -4.680557),
    new google.maps.LatLng(48.337888, -4.608724),
    new google.maps.LatLng(48.382521, -4.481871),
    new google.maps.LatLng(48.382499, -4.437269),
    new google.maps.LatLng(48.397248, -4.431541),
    new google.maps.LatLng(48.390916, -4.400045),
    new google.maps.LatLng(48.445043, -4.270090),
    new google.maps.LatLng(48.380534, -4.407525),
    new google.maps.LatLng(48.327267, -4.457964),
    new google.maps.LatLng(48.342196, -4.397217),
    new google.maps.LatLng(48.324328, -4.416317),
    new google.maps.LatLng(48.326306, -4.375252),
    new google.maps.LatLng(48.357923, -4.340740),
    new google.maps.LatLng(48.343134, -4.355025),
    new google.maps.LatLng(48.361870, -4.317563),
    new google.maps.LatLng(48.340226, -4.329398),
    new google.maps.LatLng(48.360431, -4.261421),
    new google.maps.LatLng(48.313519, -4.335165),
    new google.maps.LatLng(48.327231, -4.248262),
    new google.maps.LatLng(48.312470, -4.281910),
    new google.maps.LatLng(48.298828, -4.246553),
    new google.maps.LatLng(48.313927, -4.220657),
    new google.maps.LatLng(48.299236, -4.235718),
    new google.maps.LatLng(48.296363, -4.183463),
    new google.maps.LatLng(48.277816, -4.279434),
    new google.maps.LatLng(48.249026, -4.204344),
    new google.maps.LatLng(48.241446, -4.134850),
    new google.maps.LatLng(48.255538, -4.096941),
    new google.maps.LatLng(48.218294, -4.117532),
    new google.maps.LatLng(48.244461, -4.112207),
    new google.maps.LatLng(48.239079, -4.206532),
    new google.maps.LatLng(48.258180, -4.217701),
    new google.maps.LatLng(48.248823, -4.243509),
    new google.maps.LatLng(48.258808, -4.267194),
    new google.maps.LatLng(48.274255, -4.289022),
    new google.maps.LatLng(48.295920, -4.271675),
    new google.maps.LatLng(48.275154, -4.384494),
    new google.maps.LatLng(48.291670, -4.423777),
    new google.maps.LatLng(48.280861, -4.502602),
    new google.maps.LatLng(48.294760, -4.518242),
    new google.maps.LatLng(48.311170, -4.499301),
    new google.maps.LatLng(48.284079, -4.536532),
    new google.maps.LatLng(48.305283, -4.560356),
    new google.maps.LatLng(48.341947, -4.533028),
    new google.maps.LatLng(48.318946, -4.583418),
    new google.maps.LatLng(48.281741, -4.570234),
    new google.maps.LatLng(48.279418, -4.631403),
    new google.maps.LatLng(48.273552, -4.615234),
    new google.maps.LatLng(48.252679, -4.622761),
    new google.maps.LatLng(48.261965, -4.612748),
    new google.maps.LatLng(48.249662, -4.544122),
    new google.maps.LatLng(48.234433, -4.571819),
    new google.maps.LatLng(48.167776, -4.554130),
    new google.maps.LatLng(48.216774, -4.497301),
    new google.maps.LatLng(48.230420, -4.501648),
    new google.maps.LatLng(48.238849, -4.465324),
    new google.maps.LatLng(48.205181, -4.367427),
    new google.maps.LatLng(48.203887, -4.320452),
    new google.maps.LatLng(48.160754, -4.296161),
    new google.maps.LatLng(48.154184, -4.273080),
    new google.maps.LatLng(48.107512, -4.284586),
    new google.maps.LatLng(48.089424, -4.304396),
    new google.maps.LatLng(48.097719, -4.334679),
    new google.maps.LatLng(48.081093, -4.330278),
    new google.maps.LatLng(48.110159, -4.374396),
    new google.maps.LatLng(48.077382, -4.548636),
    new google.maps.LatLng(48.064838, -4.712214),
    new google.maps.LatLng(48.044408, -4.707387),
    new google.maps.LatLng(48.039365, -4.737811),
    new google.maps.LatLng(48.021967, -4.654854),
    new google.maps.LatLng(48.029369, -4.632959),
    new google.maps.LatLng(47.999618, -4.566133),
    new google.maps.LatLng(48.017134, -4.535469),
    new google.maps.LatLng(48.036982, -4.541024),
    new google.maps.LatLng(48.038575, -4.487662),
    new google.maps.LatLng(48.035493, -4.530263),
    new google.maps.LatLng(48.012081, -4.536960),
    new google.maps.LatLng(47.981778, -4.453577),
    new google.maps.LatLng(47.926654, -4.388020),
    new google.maps.LatLng(47.840209, -4.346037),
    new google.maps.LatLng(47.821763, -4.379554),
    new google.maps.LatLng(47.797483, -4.371712),
    new google.maps.LatLng(47.792371, -4.218338),
    new google.maps.LatLng(47.814924, -4.220903),
    new google.maps.LatLng(47.800279, -4.207700),
    new google.maps.LatLng(47.804375, -4.178537),
    new google.maps.LatLng(47.831818, -4.159408),
    new google.maps.LatLng(47.836103, -4.189288),
    new google.maps.LatLng(47.867584, -4.214410),
    new google.maps.LatLng(47.859604, -4.197339),
    new google.maps.LatLng(47.878126, -4.177720),
    new google.maps.LatLng(47.839244, -4.169436),
    new google.maps.LatLng(47.862038, -4.113312),
    new google.maps.LatLng(47.884019, -4.122505),
    new google.maps.LatLng(47.907126, -4.176337),
    new google.maps.LatLng(47.897378, -4.145611),
    new google.maps.LatLng(47.924044, -4.138727),
    new google.maps.LatLng(47.936438, -4.110804),
    new google.maps.LatLng(47.983666, -4.115042),
    new google.maps.LatLng(47.962344, -4.094295),
    new google.maps.LatLng(47.942848, -4.108242),
    new google.maps.LatLng(47.954439, -4.082986),
    new google.maps.LatLng(47.941287, -4.094880),
    new google.maps.LatLng(47.932995, -4.069298),
    new google.maps.LatLng(47.942110, -4.109417),
    new google.maps.LatLng(47.922461, -4.139164),
    new google.maps.LatLng(47.871779, -4.111754),
    new google.maps.LatLng(47.861201, -4.090884),
    new google.maps.LatLng(47.877660, -4.079176),
    new google.maps.LatLng(47.852563, -4.046130),
    new google.maps.LatLng(47.862233, -4.088530),
    new google.maps.LatLng(47.845604, -4.041402),
    new google.maps.LatLng(47.853687, -3.977496),
    new google.maps.LatLng(47.882735, -3.990027),
    new google.maps.LatLng(47.890774, -3.973217),
    new google.maps.LatLng(47.896466, -3.992210),
    new google.maps.LatLng(47.904475, -3.945875),
    new google.maps.LatLng(47.887111, -3.947495),
    new google.maps.LatLng(47.860112, -3.900913),
    new google.maps.LatLng(47.855650, -3.917677),
    new google.maps.LatLng(47.860076, -3.878789),
    new google.maps.LatLng(47.835716, -3.902452),
    new google.maps.LatLng(47.792044, -3.853586),
    new google.maps.LatLng(47.797282, -3.742207),
    new google.maps.LatLng(47.849312, -3.748600),
    new google.maps.LatLng(47.802927, -3.732548),
    new google.maps.LatLng(47.831405, -3.697910),
    new google.maps.LatLng(47.820522, -3.692515),
    new google.maps.LatLng(47.828985, -3.639434),
    new google.maps.LatLng(47.803803, -3.721803),
    new google.maps.LatLng(47.776793, -3.680838),
    new google.maps.LatLng(47.793030, -3.637912),
    new google.maps.LatLng(47.774964, -3.644558),
    new google.maps.LatLng(47.762927, -3.537397),
    new google.maps.LatLng(47.798868, -3.521246),
    new google.maps.LatLng(47.821989, -3.542959),
    new google.maps.LatLng(47.844002, -3.527593),
    new google.maps.LatLng(47.864893, -3.541639),
    new google.maps.LatLng(47.849375, -3.522964),
    new google.maps.LatLng(47.828099, -3.539822),
    new google.maps.LatLng(47.805038, -3.517378),
    new google.maps.LatLng(47.763200, -3.524386),
    new google.maps.LatLng(47.695339, -3.452758),
    new google.maps.LatLng(47.702565, -3.385148),
    new google.maps.LatLng(47.732410, -3.355826),
    new google.maps.LatLng(47.755696, -3.354454),
    new google.maps.LatLng(47.808588, -3.403919),
    new google.maps.LatLng(47.828650, -3.389293),
    new google.maps.LatLng(47.741587, -3.344247),
    new google.maps.LatLng(47.795474, -3.275235),
    new google.maps.LatLng(47.748330, -3.305731),
    new google.maps.LatLng(47.710298, -3.365408),
    new google.maps.LatLng(47.709973, -3.302899),
    new google.maps.LatLng(47.702828, -3.316180),
    new google.maps.LatLng(47.688382, -3.279383),
    new google.maps.LatLng(47.702180, -3.326730),
    new google.maps.LatLng(47.686427, -3.359367),
    new google.maps.LatLng(47.689436, -3.300461),
    new google.maps.LatLng(47.645488, -3.213197),
    new google.maps.LatLng(47.682771, -3.199010),
    new google.maps.LatLng(47.695932, -3.215142),
    new google.maps.LatLng(47.687495, -3.197300),
    new google.maps.LatLng(47.703332, -3.196083),
    new google.maps.LatLng(47.706289, -3.168440),
    new google.maps.LatLng(47.728287, -3.169893),
    new google.maps.LatLng(47.745503, -3.199227),
    new google.maps.LatLng(47.729002, -3.159544),
    new google.maps.LatLng(47.753746, -3.150081),
    new google.maps.LatLng(47.742842, -3.146145),
    new google.maps.LatLng(47.751366, -3.127844),
    new google.maps.LatLng(47.719589, -3.153624),
    new google.maps.LatLng(47.728384, -3.109439),
    new google.maps.LatLng(47.718221, -3.103117),
    new google.maps.LatLng(47.725690, -3.124593),
    new google.maps.LatLng(47.704112, -3.130952),
    new google.maps.LatLng(47.717007, -3.148748),
    new google.maps.LatLng(47.696872, -3.168832),
    new google.maps.LatLng(47.700843, -3.132450),
    new google.maps.LatLng(47.680141, -3.162288),
    new google.maps.LatLng(47.682544, -3.190509),
    new google.maps.LatLng(47.642350, -3.210934),
    new google.maps.LatLng(47.579596, -3.139751),
    new google.maps.LatLng(47.544483, -3.134867),
    new google.maps.LatLng(47.527777, -3.157309),
    new google.maps.LatLng(47.473133, -3.130723),
    new google.maps.LatLng(47.468837, -3.079222),
    new google.maps.LatLng(47.515572, -3.126568),
    new google.maps.LatLng(47.598363, -3.127024),
    new google.maps.LatLng(47.579726, -3.092860),
    new google.maps.LatLng(47.564688, -3.094887),
    new google.maps.LatLng(47.570309, -3.055562),
    new google.maps.LatLng(47.587487, -3.043348),
    new google.maps.LatLng(47.566522, -3.018113),
    new google.maps.LatLng(47.592053, -3.024553),
    new google.maps.LatLng(47.566259, -2.987585),
    new google.maps.LatLng(47.590774, -2.995573),
    new google.maps.LatLng(47.558694, -2.975639),
    new google.maps.LatLng(47.555501, -2.928045),
    new google.maps.LatLng(47.588889, -2.971797),
    new google.maps.LatLng(47.597562, -2.954604),
    new google.maps.LatLng(47.607663, -2.972275),
    new google.maps.LatLng(47.612547, -2.953166),
    new google.maps.LatLng(47.661091, -2.977635),
    new google.maps.LatLng(47.627387, -2.958846),
    new google.maps.LatLng(47.625507, -2.935790),
    new google.maps.LatLng(47.609757, -2.951088),
    new google.maps.LatLng(47.586049, -2.937202),
    new google.maps.LatLng(47.608884, -2.927673),
    new google.maps.LatLng(47.585647, -2.924237),
    new google.maps.LatLng(47.591560, -2.908100),
    new google.maps.LatLng(47.574148, -2.890462),
    new google.maps.LatLng(47.603467, -2.888951),
    new google.maps.LatLng(47.600798, -2.859034),
    new google.maps.LatLng(47.621832, -2.863360),
    new google.maps.LatLng(47.620930, -2.783280),
    new google.maps.LatLng(47.645015, -2.794074),
    new google.maps.LatLng(47.629187, -2.779374),
    new google.maps.LatLng(47.638388, -2.756226),
    new google.maps.LatLng(47.612788, -2.733644),
    new google.maps.LatLng(47.619154, -2.778576),
    new google.maps.LatLng(47.603107, -2.721059),
    new google.maps.LatLng(47.589692, -2.720311),
    new google.maps.LatLng(47.602237, -2.702720),
    new google.maps.LatLng(47.615195, -2.717478),
    new google.maps.LatLng(47.610212, -2.695480),
    new google.maps.LatLng(47.619883, -2.717256),
    new google.maps.LatLng(47.623420, -2.702665),
    new google.maps.LatLng(47.648823, -2.712280),
    new google.maps.LatLng(47.637314, -2.689850),
    new google.maps.LatLng(47.631577, -2.702640),
    new google.maps.LatLng(47.631370, -2.686620),
    new google.maps.LatLng(47.604809, -2.682758),
    new google.maps.LatLng(47.577083, -2.707060),
    new google.maps.LatLng(47.588035, -2.718426),
    new google.maps.LatLng(47.542295, -2.730782),
    new google.maps.LatLng(47.536350, -2.765683),
    new google.maps.LatLng(47.552504, -2.787674),
    new google.maps.LatLng(47.538194, -2.799389),
    new google.maps.LatLng(47.556630, -2.822691),
    new google.maps.LatLng(47.536542, -2.823403),
    new google.maps.LatLng(47.550075, -2.827181),
    new google.maps.LatLng(47.536576, -2.853955),
    new google.maps.LatLng(47.563744, -2.878251),
    new google.maps.LatLng(47.557403, -2.914493),
    new google.maps.LatLng(47.542221, -2.912751),
    new google.maps.LatLng(47.544759, -2.890500),
    new google.maps.LatLng(47.534342, -2.903586),
    new google.maps.LatLng(47.533572, -2.873694),
    new google.maps.LatLng(47.498714, -2.849463),
    new google.maps.LatLng(47.488552, -2.820250),
    new google.maps.LatLng(47.506420, -2.724639),
    new google.maps.LatLng(47.495824, -2.681273),
    new google.maps.LatLng(47.519230, -2.663124),
    new google.maps.LatLng(47.519063, -2.623018),
    new google.maps.LatLng(47.543030, -2.670287),
    new google.maps.LatLng(47.531226, -2.620937),
    new google.maps.LatLng(47.570600, -2.617040),
    new google.maps.LatLng(47.531033, -2.604352),
    new google.maps.LatLng(47.544093, -2.583010),
    new google.maps.LatLng(47.563523, -2.586852),
    new google.maps.LatLng(47.551149, -2.559928),
    new google.maps.LatLng(47.528554, -2.588487),
    new google.maps.LatLng(47.522221, -2.575261),
    new google.maps.LatLng(47.527854, -2.607051),
    new google.maps.LatLng(47.504576, -2.631888),
    new google.maps.LatLng(47.526379, -2.517734),
    new google.maps.LatLng(47.495287, -2.420526),
    new google.maps.LatLng(47.482627, -2.467450),
    new google.maps.LatLng(47.492635, -2.500202),
    new google.maps.LatLng(47.448039, -2.493027),
    new google.maps.LatLng(47.447745, -2.456732),
    new google.maps.LatLng(47.423204, -2.458509),
    new google.maps.LatLng(47.415511, -2.432061),
    new google.maps.LatLng(47.416083, -2.472672),
    new google.maps.LatLng(47.375683, -2.558119),
    new google.maps.LatLng(47.358811, -2.521159),
    new google.maps.LatLng(47.328755, -2.502914),
    new google.maps.LatLng(47.299110, -2.512418),
    new google.maps.LatLng(47.290921, -2.546314),
    new google.maps.LatLng(47.258317, -2.419562),
    new google.maps.LatLng(47.276444, -2.420381),
    new google.maps.LatLng(47.278794, -2.375169),
    new google.maps.LatLng(47.234396, -2.295379),
    new google.maps.LatLng(47.280622, -2.187361),
    new google.maps.LatLng(47.268472, -2.169990),
    new google.maps.LatLng(47.166181, -2.167063),
    new google.maps.LatLng(47.153666, -2.224002),
    new google.maps.LatLng(47.132468, -2.247219),
    new google.maps.LatLng(47.094244, -2.053686),
    new google.maps.LatLng(47.028905, -1.980413),
    new google.maps.LatLng(47.008629, -2.029331),
    new google.maps.LatLng(46.896883, -2.111759),
    new google.maps.LatLng(46.889652, -2.155031),
    new google.maps.LatLng(46.819061, -2.142058),
    new google.maps.LatLng(46.733925, -2.000266),
    new google.maps.LatLng(46.692771, -1.967499),
    new google.maps.LatLng(46.692706, -1.942876),
    new google.maps.LatLng(46.609850, -1.857028),
    new google.maps.LatLng(46.493417, -1.812344),
    new google.maps.LatLng(46.492759, -1.777138),
    new google.maps.LatLng(46.414221, -1.627062),
    new google.maps.LatLng(46.403589, -1.513259),
    new google.maps.LatLng(46.342677, -1.467130),
    new google.maps.LatLng(46.342850, -1.343132),
    new google.maps.LatLng(46.288691, -1.293738),
    new google.maps.LatLng(46.289559, -1.272229),
    new google.maps.LatLng(46.293546, -1.298220),
    new google.maps.LatLng(46.294301, -1.283069),
    new google.maps.LatLng(46.295329, -1.298794),
    new google.maps.LatLng(46.323927, -1.295895),
    new google.maps.LatLng(46.266371, -1.206475),
    new google.maps.LatLng(46.316088, -1.202044),
    new google.maps.LatLng(46.324358, -1.163387),
    new google.maps.LatLng(46.301736, -1.122886),
    new google.maps.LatLng(46.261343, -1.111166),
    new google.maps.LatLng(46.212631, -1.199869),
    new google.maps.LatLng(46.188953, -1.202484),
    new google.maps.LatLng(46.157447, -1.242043),
    new google.maps.LatLng(46.145764, -1.206939),
    new google.maps.LatLng(46.155490, -1.160723),
    new google.maps.LatLng(46.139283, -1.172931),
    new google.maps.LatLng(46.126545, -1.128236),
    new google.maps.LatLng(46.106150, -1.142803),
    new google.maps.LatLng(46.093795, -1.100724),
    new google.maps.LatLng(46.003832, -1.053074),
    new google.maps.LatLng(45.991762, -1.094833),
    new google.maps.LatLng(46.003639, -1.121682),
    new google.maps.LatLng(45.955877, -1.065084),
    new google.maps.LatLng(45.944383, -1.099611),
    new google.maps.LatLng(45.936699, -1.076865),
    new google.maps.LatLng(45.907245, -1.075612),
    new google.maps.LatLng(45.856463, -1.121043),
    new google.maps.LatLng(45.855929, -1.170242),
    new google.maps.LatLng(45.799787, -1.138075),
    new google.maps.LatLng(45.781575, -1.242569),
    new google.maps.LatLng(45.705896, -1.237223),
    new google.maps.LatLng(45.675060, -1.228295),
    new google.maps.LatLng(45.673630, -1.195979),
    new google.maps.LatLng(45.689061, -1.230145),
    new google.maps.LatLng(45.696696, -1.209316),
    new google.maps.LatLng(45.620297, -1.048863),
    new google.maps.LatLng(45.622631, -1.022185),
    new google.maps.LatLng(45.583014, -0.988932),
    new google.maps.LatLng(45.562411, -1.091205),
    new google.maps.LatLng(45.476111, -1.156185),
    new google.maps.LatLng(45.297869, -1.162337),
    new google.maps.LatLng(44.632198, -1.262050),
    new google.maps.LatLng(44.618748, -1.248545),
    new google.maps.LatLng(44.693705, -1.232289),
    new google.maps.LatLng(44.745354, -1.173818),
    new google.maps.LatLng(44.776038, -1.167566),
    new google.maps.LatLng(44.756407, -1.116326),
    new google.maps.LatLng(44.693562, -1.036362),
    new google.maps.LatLng(44.696105, -1.069280),
    new google.maps.LatLng(44.648352, -1.003618),
    new google.maps.LatLng(44.640020, -1.149167),
    new google.maps.LatLng(44.660110, -1.143605),
    new google.maps.LatLng(44.660726, -1.191538),
    new google.maps.LatLng(44.599510, -1.212134),
    new google.maps.LatLng(44.545757, -1.259408),
    new google.maps.LatLng(44.467236, -1.253922),
    new google.maps.LatLng(44.171604, -1.306621),
    new google.maps.LatLng(43.645608, -1.446805),
    new google.maps.LatLng(43.426738, -1.609735),
    new google.maps.LatLng(43.388380, -1.667757),
    new google.maps.LatLng(43.397601, -1.678447),
    new google.maps.LatLng(43.378378, -1.788749),
    new google.maps.LatLng(43.352107, -1.787346),
    new google.maps.LatLng(43.330406, -1.737710),
    new google.maps.LatLng(43.295719, -1.729876),
    new google.maps.LatLng(43.314079, -1.665759),
    new google.maps.LatLng(43.306066, -1.624075),
    new google.maps.LatLng(43.252129, -1.608932),
    new google.maps.LatLng(43.249693, -1.575423),
    new google.maps.LatLng(43.287913, -1.564716),
    new google.maps.LatLng(43.293857, -1.513662),
    new google.maps.LatLng(43.266517, -1.438853),
    new google.maps.LatLng(43.273415, -1.413206),
    new google.maps.LatLng(43.253297, -1.382786),
    new google.maps.LatLng(43.190817, -1.383825),
    new google.maps.LatLng(43.128632, -1.414157),
    new google.maps.LatLng(43.085701, -1.472765),
    new google.maps.LatLng(43.045028, -1.435187),
    new google.maps.LatLng(43.028468, -1.354752),
    new google.maps.LatLng(43.107775, -1.332245),
    new google.maps.LatLng(43.118569, -1.270184),
    new google.maps.LatLng(43.070111, -1.308758),
    new google.maps.LatLng(43.044563, -1.264286),
    new google.maps.LatLng(43.051394, -1.212414),
    new google.maps.LatLng(43.026043, -1.148263),
    new google.maps.LatLng(43.010206, -1.142568),
    new google.maps.LatLng(43.020498, -1.110663),
    new google.maps.LatLng(42.998500, -1.073203),
    new google.maps.LatLng(42.994381, -1.017554),
    new google.maps.LatLng(42.954058, -0.946455),
    new google.maps.LatLng(42.964315, -0.908245),
    new google.maps.LatLng(42.951277, -0.811107),
    new google.maps.LatLng(42.967708, -0.752961),
    new google.maps.LatLng(42.891320, -0.725296),
    new google.maps.LatLng(42.882121, -0.676034),
    new google.maps.LatLng(42.832404, -0.603259),
    new google.maps.LatLng(42.806308, -0.600760),
    new google.maps.LatLng(42.803790, -0.566028),
    new google.maps.LatLng(42.780651, -0.567491),
    new google.maps.LatLng(42.791532, -0.529731),
    new google.maps.LatLng(42.827043, -0.505837),
    new google.maps.LatLng(42.796445, -0.444304),
    new google.maps.LatLng(42.807751, -0.409230),
    new google.maps.LatLng(42.799559, -0.392597),
    new google.maps.LatLng(42.849375, -0.313444),
    new google.maps.LatLng(42.823143, -0.243362),
    new google.maps.LatLng(42.796038, -0.213905),
    new google.maps.LatLng(42.785359, -0.178493),
    new google.maps.LatLng(42.797401, -0.159724),
    new google.maps.LatLng(42.721663, -0.107276),
    new google.maps.LatLng(42.717901, -0.068842),
    new google.maps.LatLng(42.693899, -0.060623),
    new google.maps.LatLng(42.685020, -0.014819),
    new google.maps.LatLng(42.736480, 0.175726),
    new google.maps.LatLng(42.715815, 0.259886),
    new google.maps.LatLng(42.674921, 0.292824),
    new google.maps.LatLng(42.724298, 0.360097),
    new google.maps.LatLng(42.690748, 0.429277),
    new google.maps.LatLng(42.702685, 0.529611),
    new google.maps.LatLng(42.694999, 0.586831),
    new google.maps.LatLng(42.705833, 0.597320),
    new google.maps.LatLng(42.689893, 0.670577),
    new google.maps.LatLng(42.726330, 0.678240),
    new google.maps.LatLng(42.754817, 0.641853),
    new google.maps.LatLng(42.774400, 0.666290),
    new google.maps.LatLng(42.783076, 0.644538),
    new google.maps.LatLng(42.804760, 0.670587),
    new google.maps.LatLng(42.838529, 0.659017),
    new google.maps.LatLng(42.861403, 0.708375),
    new google.maps.LatLng(42.825718, 0.858301),
    new google.maps.LatLng(42.789256, 0.927327),
    new google.maps.LatLng(42.805644, 0.959891),
    new google.maps.LatLng(42.786677, 0.984893),
    new google.maps.LatLng(42.788279, 1.079082),
    new google.maps.LatLng(42.707979, 1.169853),
    new google.maps.LatLng(42.727716, 1.229115),
    new google.maps.LatLng(42.714750, 1.277559),
    new google.maps.LatLng(42.719417, 1.357381),
    new google.maps.LatLng(42.702664, 1.351246),
    new google.maps.LatLng(42.687513, 1.388830),
    new google.maps.LatLng(42.603504, 1.440154),
    new google.maps.LatLng(42.613018, 1.476566),
    new google.maps.LatLng(42.631632, 1.468506),
    new google.maps.LatLng(42.653209, 1.493745),
    new google.maps.LatLng(42.655773, 1.549251),
    new google.maps.LatLng(42.628040, 1.601469),
    new google.maps.LatLng(42.617832, 1.736563),
    new google.maps.LatLng(42.589111, 1.726555),
    new google.maps.LatLng(42.573894, 1.786248),
    new google.maps.LatLng(42.553539, 1.738529),
    new google.maps.LatLng(42.495327, 1.729889),
    new google.maps.LatLng(42.483077, 1.830913),
    new google.maps.LatLng(42.447785, 1.891351),
    new google.maps.LatLng(42.453582, 1.935306),
    new google.maps.LatLng(42.374240, 1.971330),
    new google.maps.LatLng(42.348686, 2.016923),
    new google.maps.LatLng(42.362697, 2.083593),
    new google.maps.LatLng(42.423424, 2.156338),
    new google.maps.LatLng(42.416291, 2.201057),
    new google.maps.LatLng(42.438488, 2.257077),
    new google.maps.LatLng(42.393480, 2.431968),
    new google.maps.LatLng(42.340143, 2.481869),
    new google.maps.LatLng(42.333050, 2.532727),
    new google.maps.LatLng(42.357115, 2.561869),
    new google.maps.LatLng(42.341177, 2.671770),
    new google.maps.LatLng(42.386415, 2.651499),
    new google.maps.LatLng(42.387530, 2.671415),
    new google.maps.LatLng(42.404705, 2.673948),
    new google.maps.LatLng(42.425197, 2.749186),
    new google.maps.LatLng(42.412170, 2.778123),
    new google.maps.LatLng(42.458522, 2.841416),
    new google.maps.LatLng(42.467341, 2.870306),
    new google.maps.LatLng(42.456081, 2.918447),
    new google.maps.LatLng(42.481797, 2.947028),
    new google.maps.LatLng(42.465794, 2.968992),
    new google.maps.LatLng(42.474390, 3.040455),
    new google.maps.LatLng(42.425513, 3.085441),
    new google.maps.LatLng(42.435034, 3.174682),
    new google.maps.LatLng(42.477773, 3.153008),
    new google.maps.LatLng(42.485333, 3.130192),
    new google.maps.LatLng(42.505674, 3.122975),
    new google.maps.LatLng(42.515101, 3.138784),
    new google.maps.LatLng(42.543861, 3.052075),
    new google.maps.LatLng(42.702661, 3.042623),
    new google.maps.LatLng(42.705222, 3.027737),
    new google.maps.LatLng(42.917063, 3.060589),
    new google.maps.LatLng(42.931287, 3.039630),
    new google.maps.LatLng(42.960156, 3.042844),
    new google.maps.LatLng(43.091320, 3.109210),
    new google.maps.LatLng(43.164384, 3.176287),
    new google.maps.LatLng(43.241993, 3.287114),
    new google.maps.LatLng(43.288704, 3.408554),
    new google.maps.LatLng(43.273329, 3.510959),
    new google.maps.LatLng(43.384774, 3.646079),
    new google.maps.LatLng(43.401095, 3.723035),
    new google.maps.LatLng(43.415793, 3.724920),
    new google.maps.LatLng(43.441039, 3.796669),
    new google.maps.LatLng(43.506871, 3.886401),
    new google.maps.LatLng(43.550034, 4.000968),
    new google.maps.LatLng(43.548102, 4.119360),
    new google.maps.LatLng(43.531898, 4.139120),
    new google.maps.LatLng(43.508711, 4.116589),
    new google.maps.LatLng(43.489451, 4.127202),
    new google.maps.LatLng(43.465779, 4.193863),
    new google.maps.LatLng(43.446791, 4.553088),
    new google.maps.LatLng(43.406414, 4.595021),
    new google.maps.LatLng(43.388324, 4.557426),
    new google.maps.LatLng(43.373973, 4.560754),
    new google.maps.LatLng(43.346411, 4.661784),
    new google.maps.LatLng(43.349936, 4.759595),
    new google.maps.LatLng(43.332619, 4.855045),
    new google.maps.LatLng(43.379312, 4.917391),
    new google.maps.LatLng(43.356099, 4.881741),
    new google.maps.LatLng(43.375627, 4.828715),
    new google.maps.LatLng(43.390037, 4.871828),
    new google.maps.LatLng(43.394525, 4.838686),
    new google.maps.LatLng(43.400770, 4.861468),
    new google.maps.LatLng(43.424260, 4.824366),
    new google.maps.LatLng(43.410513, 4.877303),
    new google.maps.LatLng(43.453983, 4.860832),
    new google.maps.LatLng(43.419277, 4.882222),
    new google.maps.LatLng(43.429739, 4.905523),
    new google.maps.LatLng(43.404121, 4.893499),
    new google.maps.LatLng(43.430912, 4.919330),
    new google.maps.LatLng(43.425434, 4.969221),
    new google.maps.LatLng(43.400448, 4.976842),
    new google.maps.LatLng(43.398216, 5.001068),
    new google.maps.LatLng(43.391777, 4.987638),
    new google.maps.LatLng(43.342937, 5.019048),
    new google.maps.LatLng(43.324311, 5.052378),
    new google.maps.LatLng(43.328195, 5.224098),
    new google.maps.LatLng(43.360671, 5.312079),
    new google.maps.LatLng(43.294520, 5.373820),
    new google.maps.LatLng(43.282057, 5.345483),
    new google.maps.LatLng(43.255830, 5.376082),
    new google.maps.LatLng(43.212498, 5.345610),
    new google.maps.LatLng(43.210396, 5.457562),
    new google.maps.LatLng(43.196791, 5.500289),
    new google.maps.LatLng(43.214481, 5.537236),
    new google.maps.LatLng(43.162546, 5.600895),
    new google.maps.LatLng(43.186982, 5.624163),
    new google.maps.LatLng(43.179680, 5.686735),
    new google.maps.LatLng(43.143590, 5.694875),
    new google.maps.LatLng(43.138932, 5.771254),
    new google.maps.LatLng(43.116904, 5.774495),
    new google.maps.LatLng(43.114763, 5.808860),
    new google.maps.LatLng(43.070117, 5.793603),
    new google.maps.LatLng(43.050349, 5.827638),
    new google.maps.LatLng(43.046619, 5.857455),
    new google.maps.LatLng(43.078033, 5.890200),
    new google.maps.LatLng(43.067743, 5.947031),
    new google.maps.LatLng(43.084560, 5.938208),
    new google.maps.LatLng(43.081335, 5.895300),
    new google.maps.LatLng(43.100461, 5.909107),
    new google.maps.LatLng(43.108279, 5.881202),
    new google.maps.LatLng(43.123902, 5.920878),
    new google.maps.LatLng(43.102409, 5.925444),
    new google.maps.LatLng(43.103799, 6.005016),
    new google.maps.LatLng(43.078257, 6.021534),
    new google.maps.LatLng(43.083255, 6.115132),
    new google.maps.LatLng(43.048009, 6.131609),
    new google.maps.LatLng(43.040322, 6.095066),
    new google.maps.LatLng(43.028584, 6.095624),
    new google.maps.LatLng(43.026960, 6.147900),
    new google.maps.LatLng(43.032817, 6.171243),
    new google.maps.LatLng(43.045856, 6.149960),
    new google.maps.LatLng(43.088452, 6.159837),
    new google.maps.LatLng(43.115791, 6.200785),
    new google.maps.LatLng(43.120741, 6.274941),
    new google.maps.LatLng(43.088316, 6.364495),
    new google.maps.LatLng(43.119904, 6.359763),
    new google.maps.LatLng(43.144628, 6.386458),
    new google.maps.LatLng(43.150224, 6.493496),
    new google.maps.LatLng(43.188633, 6.559638),
    new google.maps.LatLng(43.184287, 6.597203),
    new google.maps.LatLng(43.160707, 6.620228),
    new google.maps.LatLng(43.167405, 6.646376),
    new google.maps.LatLng(43.187689, 6.645719),
    new google.maps.LatLng(43.200536, 6.680104),
    new google.maps.LatLng(43.241347, 6.664433),
    new google.maps.LatLng(43.266402, 6.697133),
    new google.maps.LatLng(43.278869, 6.677683),
    new google.maps.LatLng(43.264611, 6.664192),
    new google.maps.LatLng(43.274235, 6.639855),
    new google.maps.LatLng(43.262266, 6.593463),
    new google.maps.LatLng(43.277308, 6.583874),
    new google.maps.LatLng(43.318220, 6.665956),
    new google.maps.LatLng(43.340295, 6.680028),
    new google.maps.LatLng(43.346948, 6.716237),
    new google.maps.LatLng(43.418919, 6.746928),
    new google.maps.LatLng(43.410616, 6.851974),
    new google.maps.LatLng(43.433253, 6.859738),
    new google.maps.LatLng(43.428580, 6.891804),
    new google.maps.LatLng(43.450684, 6.922217),
    new google.maps.LatLng(43.483643, 6.931091),
    new google.maps.LatLng(43.485497, 6.948840),
    new google.maps.LatLng(43.501284, 6.956279),
    new google.maps.LatLng(43.516391, 6.938364),
    new google.maps.LatLng(43.542152, 6.963334),
    new google.maps.LatLng(43.550860, 7.015707),
    new google.maps.LatLng(43.541583, 7.040444),
    new google.maps.LatLng(43.570893, 7.096258),
    new google.maps.LatLng(43.559493, 7.121754),
    new google.maps.LatLng(43.542515, 7.119229),
    new google.maps.LatLng(43.545621, 7.138503),
    new google.maps.LatLng(43.587035, 7.121796),
    new google.maps.LatLng(43.653851, 7.157847),
    new google.maps.LatLng(43.657918, 7.194540),
    new google.maps.LatLng(43.645639, 7.206452),
    new google.maps.LatLng(43.688481, 7.241886),
    new google.maps.LatLng(43.684826, 7.304020),
    new google.maps.LatLng(43.706536, 7.314716),
    new google.maps.LatLng(43.674519, 7.331301),
    new google.maps.LatLng(43.686267, 7.350117),
    new google.maps.LatLng(43.689603, 7.333878),
    new google.maps.LatLng(43.711714, 7.337594),
    new google.maps.LatLng(43.719629, 7.406457),
    new google.maps.LatLng(43.759027, 7.455404),
    new google.maps.LatLng(43.748997, 7.486834),
    new google.maps.LatLng(43.767195, 7.490211),
    new google.maps.LatLng(43.784013, 7.529821),
    new google.maps.LatLng(43.874829, 7.501212),
    new google.maps.LatLng(43.898703, 7.561097),
    new google.maps.LatLng(43.947247, 7.569849),
    new google.maps.LatLng(43.977415, 7.652628),
    new google.maps.LatLng(43.998468, 7.670205),
    new google.maps.LatLng(44.028920, 7.662695),
    new google.maps.LatLng(44.040792, 7.700027),
    new google.maps.LatLng(44.065431, 7.714073),
    new google.maps.LatLng(44.083317, 7.716510),
    new google.maps.LatLng(44.130757, 7.666595),
    new google.maps.LatLng(44.174017, 7.684589),
    new google.maps.LatLng(44.177107, 7.636620),
    new google.maps.LatLng(44.149672, 7.614560),
    new google.maps.LatLng(44.129363, 7.435355),
    new google.maps.LatLng(44.112008, 7.425250),
    new google.maps.LatLng(44.123786, 7.386271),
    new google.maps.LatLng(44.116367, 7.361410),
    new google.maps.LatLng(44.145160, 7.344200),
    new google.maps.LatLng(44.148032, 7.262787),
    new google.maps.LatLng(44.200029, 7.184271),
    new google.maps.LatLng(44.200233, 7.143114),
    new google.maps.LatLng(44.231620, 7.079209),
    new google.maps.LatLng(44.236435, 7.008059),
    new google.maps.LatLng(44.280890, 6.993447),
    new google.maps.LatLng(44.298638, 6.956527),
    new google.maps.LatLng(44.350935, 6.918949),
    new google.maps.LatLng(44.361051, 6.887435),
    new google.maps.LatLng(44.420740, 6.892740),
    new google.maps.LatLng(44.432058, 6.944558),
    new google.maps.LatLng(44.480160, 6.878521),
    new google.maps.LatLng(44.529125, 6.854014),
    new google.maps.LatLng(44.573368, 6.932869),
    new google.maps.LatLng(44.602839, 6.937515),
    new google.maps.LatLng(44.625347, 6.966820),
    new google.maps.LatLng(44.654284, 6.948175),
    new google.maps.LatLng(44.677767, 6.962647),
    new google.maps.LatLng(44.690442, 6.990357),
    new google.maps.LatLng(44.680915, 7.077105),
    new google.maps.LatLng(44.789676, 6.999400),
    new google.maps.LatLng(44.821493, 7.021786),
    new google.maps.LatLng(44.841848, 7.002096),
    new google.maps.LatLng(44.862026, 6.933686),
    new google.maps.LatLng(44.845420, 6.913765),
    new google.maps.LatLng(44.852903, 6.859866),
    new google.maps.LatLng(44.905654, 6.750707),
    new google.maps.LatLng(44.930763, 6.761039),
    new google.maps.LatLng(44.937169, 6.745140),
    new google.maps.LatLng(44.961965, 6.765240),
    new google.maps.LatLng(44.989229, 6.737852),
    new google.maps.LatLng(45.012636, 6.745518),
    new google.maps.LatLng(45.019618, 6.673884),
    new google.maps.LatLng(45.071636, 6.662110),
    new google.maps.LatLng(45.103002, 6.626718),
    new google.maps.LatLng(45.140123, 6.680069),
    new google.maps.LatLng(45.136742, 6.740025),
    new google.maps.LatLng(45.159740, 6.767941),
    new google.maps.LatLng(45.127200, 6.847885),
    new google.maps.LatLng(45.137158, 6.893913),
    new google.maps.LatLng(45.167033, 6.891601),
    new google.maps.LatLng(45.170911, 6.943064),
    new google.maps.LatLng(45.208058, 6.968762),
    new google.maps.LatLng(45.225810, 7.047203),
    new google.maps.LatLng(45.214244, 7.079601),
    new google.maps.LatLng(45.255693, 7.137593),
    new google.maps.LatLng(45.326509, 7.110693),
    new google.maps.LatLng(45.331243, 7.134713),
    new google.maps.LatLng(45.404045, 7.185633),
    new google.maps.LatLng(45.433485, 7.114351),
    new google.maps.LatLng(45.468443, 7.103029),
    new google.maps.LatLng(45.472683, 7.048826),
    new google.maps.LatLng(45.495797, 7.053169),
    new google.maps.LatLng(45.504790, 6.999979),
    new google.maps.LatLng(45.575637, 6.995384),
    new google.maps.LatLng(45.589885, 6.977847),
    new google.maps.LatLng(45.639566, 7.001114),
    new google.maps.LatLng(45.654572, 6.963092),
    new google.maps.LatLng(45.651735, 6.914828),
    new google.maps.LatLng(45.680694, 6.903030),
    new google.maps.LatLng(45.689675, 6.847069),
    new google.maps.LatLng(45.726275, 6.811541),
    new google.maps.LatLng(45.815846, 6.803985),
    new google.maps.LatLng(45.834974, 6.818078),
    new google.maps.LatLng(45.838456, 6.849305),
    new google.maps.LatLng(45.824662, 6.868474),
    new google.maps.LatLng(45.849149, 6.879136),
    new google.maps.LatLng(45.846733, 6.939609),
    new google.maps.LatLng(45.868199, 6.991725),
    new google.maps.LatLng(45.903283, 7.007420),
    new google.maps.LatLng(45.922446, 7.045065),
    new google.maps.LatLng(45.954533, 7.037441),
    new google.maps.LatLng(45.969760, 7.009409),
    new google.maps.LatLng(45.984185, 7.018252),
    new google.maps.LatLng(46.064563, 6.937369),
    new google.maps.LatLng(46.043121, 6.888327),
    new google.maps.LatLng(46.052045, 6.872633),
    new google.maps.LatLng(46.124127, 6.897025),
    new google.maps.LatLng(46.138616, 6.796535),
    new google.maps.LatLng(46.204343, 6.803663),
    new google.maps.LatLng(46.280226, 6.864802),
    new google.maps.LatLng(46.347813, 6.773676),
    new google.maps.LatLng(46.394137, 6.805313),
    new google.maps.LatLng(46.407870, 6.716898),
    new google.maps.LatLng(46.394725, 6.545176),
    new google.maps.LatLng(46.404785, 6.513070),
    new google.maps.LatLng(46.340163, 6.390033),
    new google.maps.LatLng(46.370015, 6.344681),
    new google.maps.LatLng(46.366712, 6.304859),
    new google.maps.LatLng(46.304328, 6.241717),
    new google.maps.LatLng(46.277337, 6.237799),
    new google.maps.LatLng(46.248112, 6.266285),
    new google.maps.LatLng(46.264592, 6.294881),
    new google.maps.LatLng(46.255106, 6.308540),
    new google.maps.LatLng(46.224925, 6.294465),
    new google.maps.LatLng(46.192152, 6.206737),
    new google.maps.LatLng(46.141528, 6.136181),
    new google.maps.LatLng(46.151403, 6.052041),
    new google.maps.LatLng(46.138498, 6.038536),
    new google.maps.LatLng(46.132090, 5.956067),
    new google.maps.LatLng(46.182994, 5.995292),
    new google.maps.LatLng(46.196964, 5.963678),
    new google.maps.LatLng(46.215330, 5.975150),
    new google.maps.LatLng(46.251016, 6.124246),
    new google.maps.LatLng(46.284847, 6.102369),
    new google.maps.LatLng(46.365574, 6.168984),
    new google.maps.LatLng(46.416223, 6.064006),
    new google.maps.LatLng(46.442604, 6.086207),
    new google.maps.LatLng(46.463950, 6.073930),
    new google.maps.LatLng(46.545332, 6.156563),
    new google.maps.LatLng(46.577592, 6.111617),
    new google.maps.LatLng(46.683194, 6.270488),
    new google.maps.LatLng(46.761751, 6.438105),
    new google.maps.LatLng(46.788760, 6.458652),
    new google.maps.LatLng(46.812410, 6.431002),
    new google.maps.LatLng(46.890211, 6.464578),
    new google.maps.LatLng(46.928606, 6.432675),
    new google.maps.LatLng(46.974183, 6.496686),
    new google.maps.LatLng(46.965849, 6.505219),
    new google.maps.LatLng(46.992039, 6.618679),
    new google.maps.LatLng(47.039039, 6.699656),
    new google.maps.LatLng(47.051241, 6.716602),
    new google.maps.LatLng(47.066506, 6.691671),
    new google.maps.LatLng(47.083034, 6.707258),
    new google.maps.LatLng(47.157637, 6.850788),
    new google.maps.LatLng(47.171691, 6.841667),
    new google.maps.LatLng(47.243283, 6.955403),
    new google.maps.LatLng(47.287757, 6.942892),
    new google.maps.LatLng(47.297726, 6.999560),
    new google.maps.LatLng(47.324359, 7.009728),
    new google.maps.LatLng(47.334261, 7.056525),
    new google.maps.LatLng(47.361782, 7.049013),
    new google.maps.LatLng(47.371835, 7.016175),
    new google.maps.LatLng(47.352450, 6.879265),
    new google.maps.LatLng(47.405255, 6.916812),
    new google.maps.LatLng(47.406022, 6.938378),
    new google.maps.LatLng(47.433372, 6.940542),
    new google.maps.LatLng(47.452298, 6.998968),
    new google.maps.LatLng(47.494257, 6.982901),
    new google.maps.LatLng(47.504210, 7.024394),
    new google.maps.LatLng(47.488193, 7.075893),
    new google.maps.LatLng(47.503029, 7.130341),
    new google.maps.LatLng(47.503029, 7.130341)],
    [new google.maps.LatLng(42.461623, 1.957380),
    new google.maps.LatLng(42.447525, 1.981342),
    new google.maps.LatLng(42.451680, 2.014379),
    new google.maps.LatLng(42.469686, 1.987295),
    new google.maps.LatLng(42.484976, 1.998387),
    new google.maps.LatLng(42.494087, 1.985237),
    new google.maps.LatLng(42.461623, 1.957380),
    new google.maps.LatLng(42.461623, 1.957380)],
    [new google.maps.LatLng(43.469321, 5.017834),
    new google.maps.LatLng(43.464688, 5.050859),
    new google.maps.LatLng(43.403975, 5.059568),
    new google.maps.LatLng(43.399700, 5.081662),
    new google.maps.LatLng(43.401114, 5.139914),
    new google.maps.LatLng(43.465078, 5.230138),
    new google.maps.LatLng(43.491250, 5.203447),
    new google.maps.LatLng(43.458569, 5.145318),
    new google.maps.LatLng(43.523028, 5.113745),
    new google.maps.LatLng(43.522139, 5.046435),
    new google.maps.LatLng(43.556257, 5.016893),
    new google.maps.LatLng(43.498106, 4.998549),
    new google.maps.LatLng(43.474094, 5.000858),
    new google.maps.LatLng(43.469321, 5.017834),
    new google.maps.LatLng(43.469321, 5.017834)
    ], [
    new google.maps.LatLng(48.635034, -1.510630),
    new google.maps.LatLng(48.635034, -1.510630),
    new google.maps.LatLng(48.635034, -1.510630)
    ], [
    new google.maps.LatLng(46.709424, -2.304788),
    new google.maps.LatLng(46.732424, -2.371518),
    new google.maps.LatLng(46.723952, -2.398761),
    new google.maps.LatLng(46.686336, -2.333909),
    new google.maps.LatLng(46.688375, -2.282680),
    new google.maps.LatLng(46.709424, -2.304788),
    new google.maps.LatLng(46.709424, -2.304788)
    ], [
    new google.maps.LatLng(46.951171, -2.198072),
    new google.maps.LatLng(46.893374, -2.148572),
    new google.maps.LatLng(46.950033, -2.157181),
    new google.maps.LatLng(46.973216, -2.230739),
    new google.maps.LatLng(46.994023, -2.246799),
    new google.maps.LatLng(46.991065, -2.219219),
    new google.maps.LatLng(47.016683, -2.225411),
    new google.maps.LatLng(47.024881, -2.307551),
    new google.maps.LatLng(47.011446, -2.292093),
    new google.maps.LatLng(46.989779, -2.301949),
    new google.maps.LatLng(46.960150, -2.263390),
    new google.maps.LatLng(46.965523, -2.224754),
    new google.maps.LatLng(46.951171, -2.198072),
    new google.maps.LatLng(46.951171, -2.198072)
    ], [
    new google.maps.LatLng(48.836321, -3.002321),
    new google.maps.LatLng(48.864917, -2.986551),
    new google.maps.LatLng(48.857825, -3.012907),
    new google.maps.LatLng(48.836321, -3.002321),
    new google.maps.LatLng(48.836321, -3.002321)
    ], [
    new google.maps.LatLng(48.794095, -3.583877),
    new google.maps.LatLng(48.807749, -3.565774),
    new google.maps.LatLng(48.794095, -3.583877),
    new google.maps.LatLng(48.794095, -3.583877)
    ], [
    new google.maps.LatLng(48.031546, -4.846003),
    new google.maps.LatLng(48.043855, -4.867548),
    new google.maps.LatLng(48.031546, -4.846003),
    new google.maps.LatLng(48.031546, -4.846003)
    ], [
    new google.maps.LatLng(48.389414, -4.959191),
    new google.maps.LatLng(48.401696, -4.961121),
    new google.maps.LatLng(48.389414, -4.959191),
    new google.maps.LatLng(48.389414, -4.959191)
    ], [
    new google.maps.LatLng(48.482730, -5.072523),
    new google.maps.LatLng(48.448414, -5.138001),
    new google.maps.LatLng(48.455452, -5.098430),
    new google.maps.LatLng(48.436350, -5.105912),
    new google.maps.LatLng(48.458862, -5.036844),
    new google.maps.LatLng(48.482730, -5.072523),
    new google.maps.LatLng(48.482730, -5.072523)
    ], [
    new google.maps.LatLng(48.736400, -3.991811),
    new google.maps.LatLng(48.752082, -4.006229),
    new google.maps.LatLng(48.746527, -4.039777),
    new google.maps.LatLng(48.736400, -3.991811),
    new google.maps.LatLng(48.736400, -3.991811)
    ], [
    new google.maps.LatLng(47.342634, -2.882836),
    new google.maps.LatLng(47.332205, -2.885198),
    new google.maps.LatLng(47.340822, -2.861042),
    new google.maps.LatLng(47.342634, -2.882836),
    new google.maps.LatLng(47.342634, -2.882836)
    ], [
    new google.maps.LatLng(47.619998, -3.421793),
    new google.maps.LatLng(47.642297, -3.429485),
    new google.maps.LatLng(47.650913, -3.513272),
    new google.maps.LatLng(47.620346, -3.461914),
    new google.maps.LatLng(47.619998, -3.421793),
    new google.maps.LatLng(47.619998, -3.421793)
    ], [
    new google.maps.LatLng(47.373337, -2.948149),
    new google.maps.LatLng(47.394698, -2.939379),
    new google.maps.LatLng(47.402098, -2.992795),
    new google.maps.LatLng(47.373337, -2.948149),
    new google.maps.LatLng(47.373337, -2.948149)
    ], [
    new google.maps.LatLng(47.323814, -3.235440),
    new google.maps.LatLng(47.314387, -3.248577),
    new google.maps.LatLng(47.293850, -3.219124),
    new google.maps.LatLng(47.301593, -3.167634),
    new google.maps.LatLng(47.278221, -3.092817),
    new google.maps.LatLng(47.308175, -3.055895),
    new google.maps.LatLng(47.329840, -3.139647),
    new google.maps.LatLng(47.362941, -3.158024),
    new google.maps.LatLng(47.373641, -3.215693),
    new google.maps.LatLng(47.363285, -3.218197),
    new google.maps.LatLng(47.378419, -3.217584),
    new google.maps.LatLng(47.387542, -3.247216),
    new google.maps.LatLng(47.352257, -3.263557),
    new google.maps.LatLng(47.323814, -3.235440),
    new google.maps.LatLng(47.323814, -3.235440)
    ], [
    new google.maps.LatLng(47.559896, -2.859562),
    new google.maps.LatLng(47.587384, -2.849595),
    new google.maps.LatLng(47.588084, -2.825813),
    new google.maps.LatLng(47.607326, -2.838014),
    new google.maps.LatLng(47.559896, -2.859562),
    new google.maps.LatLng(47.559896, -2.859562)
    ], [
    new google.maps.LatLng(47.578258, -2.809605),
    new google.maps.LatLng(47.600093, -2.788180),
    new google.maps.LatLng(47.578258, -2.809605),
    new google.maps.LatLng(47.578258, -2.809605)
    ], [
    new google.maps.LatLng(46.210027, -1.480904),
    new google.maps.LatLng(46.212270, -1.510386),
    new google.maps.LatLng(46.234852, -1.515161),
    new google.maps.LatLng(46.233255, -1.474548),
    new google.maps.LatLng(46.256176, -1.494657),
    new google.maps.LatLng(46.244937, -1.562615),
    new google.maps.LatLng(46.204393, -1.535323),
    new google.maps.LatLng(46.193801, -1.505191),
    new google.maps.LatLng(46.202119, -1.461605),
    new google.maps.LatLng(46.143375, -1.311738),
    new google.maps.LatLng(46.161972, -1.255560),
    new google.maps.LatLng(46.205941, -1.357061),
    new google.maps.LatLng(46.213777, -1.444316),
    new google.maps.LatLng(46.230134, -1.412938),
    new google.maps.LatLng(46.229067, -1.465384),
    new google.maps.LatLng(46.210027, -1.480904),
    new google.maps.LatLng(46.210027, -1.480904)
    ], [
    new google.maps.LatLng(46.008006, -1.175593),
    new google.maps.LatLng(46.020699, -1.155467),
    new google.maps.LatLng(46.024341, -1.173022),
    new google.maps.LatLng(46.008006, -1.175593),
    new google.maps.LatLng(46.008006, -1.175593)
    ], [
    new google.maps.LatLng(45.845963, -1.250260),
    new google.maps.LatLng(45.802015, -1.229578),
    new google.maps.LatLng(45.829007, -1.195491),
    new google.maps.LatLng(45.850024, -1.207754),
    new google.maps.LatLng(45.885633, -1.188004),
    new google.maps.LatLng(45.927059, -1.233773),
    new google.maps.LatLng(45.983903, -1.237545),
    new google.maps.LatLng(45.992976, -1.312760),
    new google.maps.LatLng(46.039342, -1.371273),
    new google.maps.LatLng(46.047890, -1.412406),
    new google.maps.LatLng(45.951445, -1.383917),
    new google.maps.LatLng(45.881044, -1.268968),
    new google.maps.LatLng(45.845963, -1.250260),
    new google.maps.LatLng(45.845963, -1.250260)
    ], [
    new google.maps.LatLng(43.013347, 6.434149),
    new google.maps.LatLng(43.004198, 6.437335),
    new google.maps.LatLng(43.013939, 6.461700),
    new google.maps.LatLng(43.051129, 6.507958),
    new google.maps.LatLng(43.045168, 6.470216),
    new google.maps.LatLng(43.013347, 6.434149),
    new google.maps.LatLng(43.013347, 6.434149)
    ], [
    new google.maps.LatLng(42.992802, 6.397091),
    new google.maps.LatLng(43.010728, 6.419597),
    new google.maps.LatLng(43.018283, 6.410165),
    new google.maps.LatLng(43.001244, 6.369720),
    new google.maps.LatLng(42.992802, 6.397091),
    new google.maps.LatLng(42.992802, 6.397091)
    ], [
    new google.maps.LatLng(43.020035, 6.244074),
    new google.maps.LatLng(43.002144, 6.210604),
    new google.maps.LatLng(43.000082, 6.163146),
    new google.maps.LatLng(42.982033, 6.205267),
    new google.maps.LatLng(42.998795, 6.249984),
    new google.maps.LatLng(43.020035, 6.244074),
    new google.maps.LatLng(43.020035, 6.244074)
    ], [
    new google.maps.LatLng(43.513649, 7.067118),
    new google.maps.LatLng(43.522345, 7.031498),
    new google.maps.LatLng(43.513649, 7.067118),
    new google.maps.LatLng(43.513649, 7.067118)
    ], [
    new google.maps.LatLng(41.364959, 9.271032),
    new google.maps.LatLng(41.371036, 9.255866),
    new google.maps.LatLng(41.364959, 9.271032),
    new google.maps.LatLng(41.364959, 9.271032)
    ], [
    new google.maps.LatLng(41.858702, 9.402271),
    new google.maps.LatLng(41.958796, 9.415579),
    new google.maps.LatLng(42.104166, 9.549980),
    new google.maps.LatLng(42.276000, 9.559598),
    new google.maps.LatLng(42.366280, 9.533370),
    new google.maps.LatLng(42.545947, 9.533196),
    new google.maps.LatLng(42.673577, 9.446189),
    new google.maps.LatLng(42.805100, 9.492385),
    new google.maps.LatLng(42.961755, 9.451580),
    new google.maps.LatLng(42.986401, 9.463558),
    new google.maps.LatLng(43.011724, 9.421758),
    new google.maps.LatLng(42.994465, 9.340873),
    new google.maps.LatLng(42.923198, 9.359698),
    new google.maps.LatLng(42.899633, 9.322223),
    new google.maps.LatLng(42.865666, 9.340298),
    new google.maps.LatLng(42.834679, 9.311015),
    new google.maps.LatLng(42.795855, 9.342312),
    new google.maps.LatLng(42.737810, 9.344478),
    new google.maps.LatLng(42.689999, 9.315448),
    new google.maps.LatLng(42.674749, 9.286764),
    new google.maps.LatLng(42.718515, 9.254448),
    new google.maps.LatLng(42.734222, 9.217627),
    new google.maps.LatLng(42.731631, 9.124552),
    new google.maps.LatLng(42.694609, 9.059094),
    new google.maps.LatLng(42.660065, 9.059321),
    new google.maps.LatLng(42.641353, 9.014949),
    new google.maps.LatLng(42.633541, 8.948383),
    new google.maps.LatLng(42.645304, 8.930660),
    new google.maps.LatLng(42.608400, 8.867392),
    new google.maps.LatLng(42.602570, 8.802628),
    new google.maps.LatLng(42.571693, 8.805133),
    new google.maps.LatLng(42.556518, 8.781054),
    new google.maps.LatLng(42.570894, 8.756211),
    new google.maps.LatLng(42.561604, 8.727044),
    new google.maps.LatLng(42.584052, 8.726812),
    new google.maps.LatLng(42.576606, 8.710990),
    new google.maps.LatLng(42.526146, 8.718489),
    new google.maps.LatLng(42.515224, 8.666509),
    new google.maps.LatLng(42.477009, 8.649443),
    new google.maps.LatLng(42.468654, 8.679827),
    new google.maps.LatLng(42.442411, 8.648238),
    new google.maps.LatLng(42.415734, 8.655230),
    new google.maps.LatLng(42.416302, 8.606518),
    new google.maps.LatLng(42.386380, 8.608844),
    new google.maps.LatLng(42.383345, 8.576701),
    new google.maps.LatLng(42.369705, 8.569457),
    new google.maps.LatLng(42.379355, 8.547948),
    new google.maps.LatLng(42.368280, 8.543146),
    new google.maps.LatLng(42.333077, 8.553131),
    new google.maps.LatLng(42.352772, 8.591544),
    new google.maps.LatLng(42.348741, 8.616249),
    new google.maps.LatLng(42.332296, 8.627996),
    new google.maps.LatLng(42.318742, 8.597819),
    new google.maps.LatLng(42.305866, 8.604636),
    new google.maps.LatLng(42.302350, 8.660475),
    new google.maps.LatLng(42.265899, 8.691159),
    new google.maps.LatLng(42.235832, 8.539575),
    new google.maps.LatLng(42.230301, 8.570341),
    new google.maps.LatLng(42.205288, 8.566475),
    new google.maps.LatLng(42.205940, 8.581386),
    new google.maps.LatLng(42.180577, 8.586237),
    new google.maps.LatLng(42.171783, 8.561221),
    new google.maps.LatLng(42.167898, 8.593545),
    new google.maps.LatLng(42.146056, 8.557789),
    new google.maps.LatLng(42.151174, 8.585702),
    new google.maps.LatLng(42.129323, 8.582518),
    new google.maps.LatLng(42.134498, 8.612561),
    new google.maps.LatLng(42.103588, 8.660503),
    new google.maps.LatLng(42.112516, 8.699986),
    new google.maps.LatLng(42.063511, 8.719856),
    new google.maps.LatLng(42.050211, 8.746987),
    new google.maps.LatLng(42.009346, 8.656425),
    new google.maps.LatLng(41.984030, 8.669220),
    new google.maps.LatLng(41.969477, 8.649623),
    new google.maps.LatLng(41.964048, 8.592048),
    new google.maps.LatLng(41.934839, 8.623394),
    new google.maps.LatLng(41.895482, 8.608119),
    new google.maps.LatLng(41.909889, 8.641450),
    new google.maps.LatLng(41.907881, 8.718568),
    new google.maps.LatLng(41.933412, 8.749611),
    new google.maps.LatLng(41.922979, 8.782535),
    new google.maps.LatLng(41.894406, 8.803142),
    new google.maps.LatLng(41.880669, 8.781212),
    new google.maps.LatLng(41.850465, 8.788230),
    new google.maps.LatLng(41.845164, 8.750928),
    new google.maps.LatLng(41.831770, 8.784224),
    new google.maps.LatLng(41.811239, 8.771296),
    new google.maps.LatLng(41.801758, 8.711985),
    new google.maps.LatLng(41.775912, 8.730088),
    new google.maps.LatLng(41.739399, 8.659651),
    new google.maps.LatLng(41.739378, 8.704554),
    new google.maps.LatLng(41.722281, 8.709236),
    new google.maps.LatLng(41.741417, 8.774980),
    new google.maps.LatLng(41.699216, 8.782835),
    new google.maps.LatLng(41.714130, 8.812712),
    new google.maps.LatLng(41.697328, 8.841977),
    new google.maps.LatLng(41.689724, 8.914508),
    new google.maps.LatLng(41.646374, 8.870864),
    new google.maps.LatLng(41.629707, 8.819686),
    new google.maps.LatLng(41.641070, 8.803326),
    new google.maps.LatLng(41.591809, 8.776175),
    new google.maps.LatLng(41.575697, 8.803337),
    new google.maps.LatLng(41.557736, 8.788535),
    new google.maps.LatLng(41.546553, 8.841681),
    new google.maps.LatLng(41.518816, 8.842507),
    new google.maps.LatLng(41.524307, 8.876670),
    new google.maps.LatLng(41.506100, 8.883149),
    new google.maps.LatLng(41.508065, 8.913154),
    new google.maps.LatLng(41.489105, 8.922762),
    new google.maps.LatLng(41.492130, 8.959459),
    new google.maps.LatLng(41.474918, 8.984435),
    new google.maps.LatLng(41.485418, 8.995613),
    new google.maps.LatLng(41.456754, 9.040540),
    new google.maps.LatLng(41.477488, 9.079937),
    new google.maps.LatLng(41.444337, 9.071766),
    new google.maps.LatLng(41.441091, 9.122199),
    new google.maps.LatLng(41.392056, 9.096498),
    new google.maps.LatLng(41.399811, 9.132767),
    new google.maps.LatLng(41.366438, 9.180369),
    new google.maps.LatLng(41.366662, 9.210490),
    new google.maps.LatLng(41.413375, 9.259167),
    new google.maps.LatLng(41.428283, 9.265102),
    new google.maps.LatLng(41.404746, 9.223687),
    new google.maps.LatLng(41.413270, 9.209784),
    new google.maps.LatLng(41.416963, 9.226381),
    new google.maps.LatLng(41.442489, 9.223954),
    new google.maps.LatLng(41.464221, 9.277942),
    new google.maps.LatLng(41.530193, 9.273074),
    new google.maps.LatLng(41.559969, 9.343414),
    new google.maps.LatLng(41.591126, 9.367796),
    new google.maps.LatLng(41.604744, 9.318344),
    new google.maps.LatLng(41.583142, 9.293906),
    new google.maps.LatLng(41.596416, 9.282534),
    new google.maps.LatLng(41.628488, 9.308237),
    new google.maps.LatLng(41.615173, 9.319785),
    new google.maps.LatLng(41.616594, 9.354609),
    new google.maps.LatLng(41.636632, 9.347601),
    new google.maps.LatLng(41.636511, 9.370047),
    new google.maps.LatLng(41.657359, 9.387491),
    new google.maps.LatLng(41.678830, 9.372301),
    new google.maps.LatLng(41.710840, 9.403533),
    new google.maps.LatLng(41.858702, 9.402271),
    new google.maps.LatLng(41.858702, 9.402271)
    ]
];
}

