import { MarkerData } from '../../models/markerData';
import { about } from './about';
import { education } from './education';
import { firstExperiences } from './first-experiences';
import { frenchRiviera } from './french-riviera';
import { newLife } from './new-life';

export const markers: MarkerData[] = [
  new MarkerData({
    id: 'about',
    latLng: { lat: 50.688791, lng: 2.871852 },
    location: 'Armentières, France',
    title: 'A propos',
    content: about,
    startDate: new Date('1974-10-20'),
    endDate: new Date('1993-08-31'),
    iconUrl: '/assets/boy.png'
  }),
  new MarkerData({
    id: 'education',
    latLng: { lat: 48.426082, lng: 2.743449 },
    location: 'Fontainebleau, France',
    title: 'Mes études',
    content: education,
    startDate: new Date('1993-09-01'),
    endDate: new Date('1996-08-31'),
    iconUrl: '/assets/man-student.png'
  }),
  new MarkerData({
    id: 'first-experiences',
    latLng: { lat: 48.871752, lng: 2.337676 },
    location: 'Paris, France',
    title: 'Mes Premières armes',
    content: firstExperiences,
    startDate: new Date('1996-09-01'),
    endDate: new Date('1999-05-31'),
    iconUrl: '/assets/man-office-worker.png'
  }),
  new MarkerData({
    id: 'french-riviera',
    latLng: { lat: 43.616354, lng: 7.055222 },
    location: 'Sophia-Antipolis, France',
    title: 'Mon parcours',
    content: frenchRiviera,
    startDate: new Date('1999-06-01'),
    endDate: new Date('2019-07-15'),
    iconUrl: '/assets/man-office-worker.png'
  }),
  new MarkerData({
    id: 'new-life',
    latLng: { lat: 46.5, lng: -1.7833300 },
    location: 'Les Sables d\'Olonne, Vendée, France',
    title: 'Ma nouvelle vie',
    content: newLife,
    startDate: new Date('2019-07-15'),
    endDate: new Date(),
    iconUrl: '/assets/man-office-worker.png'
  })
];
