export class MarkerIcon {
  url: string;
  anchor?: [number, number] = [16, 16];
  size?: [number, number] = [32, 32];
  scaledSize?: [number, number] = [32, 32];

  constructor(fields: Partial<MarkerIcon> & { url: string }) {
    Object.assign(this, fields);
  }
}
