import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private headers: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders({
      accept: 'application/vnd.app.v1+json',
      'api-token': 'key:f8MOZ9dYU4ap5F12m95PIPAA5AJG3Sh6',
      'content-type': 'application/json'
    });
  }

  sendEmail(data): Observable<HttpResponse<any>> {
    const mailData = {
      to: data.to || 'christophe.boulain@gmail.com',
      from: data.from,
      send_type: 'now',
      save: true,
      subject: data.subject,
      html: data.message
    };
    return this.httpClient
      .post<any>(environment.mailServiceUrl + '/send', mailData, {
        headers: this.headers,
        observe: 'response'
      })
      .catch(this.errorHandler);
  }

  private errorHandler(error: Response) {
    return Observable.throw(error || 'Server Error');
  }
}
