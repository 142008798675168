export const firstExperiences = [
  `<h5><a href="http://www.esigetel.fr" target="_blank">ESIGETEL</a>
  <small class="company-type">Ecole d'ingénieur</small>
    <small class="company-date">Sept. 1996 - Août 1997</small></h5>`,
  `<dl>
    <dt>Administrateur Systèmes</dt>
    <dd><ul><li>Administration des serveurs et du réseau de l'école</li></ul></dd>
  </dl>`,
  `<dl class="tech-env">
      <dt>Environnement technique :</dt>
      <dd>Serveurs UNIX (Digital UNIX, HPUX, SunOS Solaris)</dd>
      <dd>Serveurs Windows (Windows NT)</dd>
      <dd>Station de travail UNIX (Digital UNIX), et Windows (3.11)</dd>
  </dl>`,
  `<hr/>`,
  `<h5>DECYLOG <small class="company-type">SSII</small>
    <small class="company-date">Sept. 1997 - Mai 1999</small></h5>`,
  `<dl>
    <dt>Mission pour Bouygues Télécom (9 mois)</dt>
    <dd>
      <ul>
        <li>Administration serveurs UNIX</li>
        <li>Développement de scripts SHELL (KSH)</li>
        <li>Administration de bases de données</li>
      </ul>
    </dd>
  </dl>`,
  `  <dl class="tech-env">
    <dt>Environnement technique: </dt>
    <dd>Serveurs Digital UNIX</dd>
    <dd>Bases de données ORACLE</dd>
  </dl>`,
  `<dl>
    <dt>BNP Paribas (9 mois)</dt>
    <dd><ul><li>Etudes techniques sur la sécurité des systèmes et des applications</li></ul></dd>
  </dl>`,
  `  <dl class="tech-env">
    <dt>Environnement technique: </dt>
    <dd>Serveurs IBM AIX</dd>
  </dl>`,
  `<dl>
    <dt>CAMAT (2 mois)</dt>
    <dd><ul><li>Migration d'un cluster Digital UNIX</ul></li></dd>
  </dl>`,
  `  <dl class="tech-env">
    <dt>Environnement technique: </dt>
    <dd>Serveurs Digital UNIX</dd>
  </dl>`,
  `En mai 1999, je décide de quitter la région parisienne pour le <a href="/cv#french-riviera">doux climat azuréen</a>`
];
