export const about = [
  '<h5>Mes premières années dans le Nord</h5>',
  '<dl><dt> 20 octobre 1974 : 🍼 Naissance</dt><dd>à Armentières.</dd></dl>',
  `<dl><dt> 1989 : 💾 Mes premiers développements</dt><dd>en <strong>Turbo Pascal</strong>.</dd>
  <dd>Participation à la création
  et au développement d'un logiciel de gestion de cabinet de kinésithérapie.</dd>
  <dd>Première découverte des bases de données avec Borland Database Engine.</dd></dl>`,
  '<dl><dt> 1991 : 🎓 Baccalauréat série E</dt><dd>Mention AB.</dd><dd>Lycée Gustave Eiffel à Armentières.</dd></dl>',
  "<dl><dt> 1991 - 1993 : 🎓 Classes préparatoires aux grandes écoles d'ingénieur</dt><dd>Lycée Gustave Eiffel.</dd></dl>",
  '<dl><dt> Août 1993 : 🛫 Le départ pour</dt><dd>les études à <a href="/cv#education">Fontainebleau</a>...</dd></dl>'
];
