import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../../services/data.service';
import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
  keyframes
} from '@angular/animations';

@Component({
  selector: 'app-competencies',
  templateUrl: './competencies.component.html',
  styleUrls: ['./competencies.component.scss']
})
export class CompetenciesComponent implements OnInit {
  @Input() competencies: any[];
  @Input() title = '';
  @Input() justify = 'left';
  private randomOffset;

  constructor() {
    this.randomOffset = Math.floor(Math.random() * 5);
  }

  ngOnInit() {}

  getNgClasses(i) {
    return {
      'bg-success': (i + this.randomOffset) % 5 === 0,
      'bg-info': (i + this.randomOffset) % 5 === 1,
      'bg-danger': (i + this.randomOffset) % 5 === 2,
      'bg-warning': (i + this.randomOffset) % 5 === 3
    };
  }
}
