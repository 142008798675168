import { Injectable } from '@angular/core';
import { MarkerData } from '../models/markerData';
import { Subject } from 'rxjs';
import { markers } from './data/markers';
import { competencies } from './data/competencies';

const MARKERS: MarkerData[] = markers;
const COMPETENCIES: any[] = competencies;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  markersSubject = new Subject<MarkerData[]>();
  competenciesSubject = new Subject<any[]>();

  constructor() {}

  emitMarkers() {
    this.markersSubject.next(MARKERS.slice());
  }

  emitCompetencies() {
    this.competenciesSubject.next(COMPETENCIES.slice());
  }

  getMarkerById(id: string): Promise<MarkerData> {
    return new Promise((resolve, reject) => {
      const data: MarkerData = MARKERS.find((value) => {
        return value.id === id;
      });
      if (data) {
        resolve(data);
      } else {
        reject('Marker not found');
      }
    });
  }
}
