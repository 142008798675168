import { Injectable } from '@angular/core';
import { Marker } from '@ngui/map';
import { MarkerIcon } from '../models/markerIcon';

// Center point of FRANCE
const CENTER = { lat: 46.227638, lng: 2.213749 };

/**
 * Les 4 points les plus éloignés en France
 */
const BOUNDS = [
  { lat: 51.08916667, lng: 2.54527778 }, // Bray Dune, Nord
  { lat: 48.96722222, lng: 8.23055556 }, // Lauterbourg, Bas-Rhin
  { lat: 42.33277778, lng: 2.53277778 }, // Puig de coma negra, Pyrénées Orientales
  { lat: 48.41277778, lng: -4.79555556 } // Pointe de Corsen, Finistère
];

@Injectable({
  providedIn: 'root'
})
export class MapService {
  // Hold the google map
  map: google.maps.Map;
  // Hold boundaries of the map
  bounds: google.maps.LatLngBounds;
  // Map Options
  mapOptions = {
    zoom: 5,
    center: CENTER,
    mapTypeId: 'terrain',
    backgroundColor: '#fff',
    disableDefaultUI: true,
    draggable: true,
    scaleControl: false,
    scrollwheel: false,
    zoomControl: true,
    minZoom: 5,
    maxZoom: 7,

    styles: [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ visibility: 'on' }]
      },
      {
        featureType: 'landscape',
        stylers: [{ visibility: 'on' }]
      },
      {
        featureType: 'road',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'administrative',
        stylers: [{ visibility: 'on' }]
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      },
      {
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
      },
      {}
    ]
  };

  // Allow component to subscribe to markers updates
  selectedMarker: google.maps.Marker;

  constructor() {}

  // Googlemap mapReady event handler.
  mapReady(map: google.maps.Map): void {
    this.map = map;
    this.bounds = new google.maps.LatLngBounds();

    // Define bounds to allow automatic initial zooming and center
    BOUNDS.forEach((element, index) => {
      this.bounds.extend(new google.maps.LatLng(element.lat, element.lng));
    });

    this.centerMap();
  }

  // Center the map according to defined bounds
  centerMap(): void {
    this.map.fitBounds(this.bounds);
    this.map.panToBounds(this.bounds);
  }

  // Called after marker initialisation
  initMarker(marker: google.maps.Marker): void {}

  // EndDrag event handler to limit draggable zone to France
  limitDrag(): void {
    if (this.bounds.contains(this.map.getCenter())) {
      return;
    }

    // We're out of bounds - Move the map back within the bounds
    const c = this.map.getCenter(),
      maxX = this.bounds.getNorthEast().lng(),
      maxY = this.bounds.getNorthEast().lat(),
      minX = this.bounds.getSouthWest().lng(),
      minY = this.bounds.getSouthWest().lat();

    let x = c.lng(),
      y = c.lat();

    if (x < minX) {
      x = minX;
    }
    if (x > maxX) {
      x = maxX;
    }
    if (y < minY) {
      y = minY;
    }
    if (y > maxY) {
      y = maxY;
    }

    this.map.setCenter(new google.maps.LatLng(y, x));
  }
}
