import {
  Component,
  HostListener,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {} from '@types/googlemaps';
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd
} from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { NavComponent } from './header/nav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Christophe BOULAIN';
  showIntro = true;
  private htmlTag: HTMLElement;

  constructor(
    element: ElementRef,
    private router: Router,
    private renderer: Renderer2
  ) {
    // Get HTML root tag.
    this.htmlTag = element.nativeElement.parentElement.parentElement;
    // Navigation events => Send to Google Analytics
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showIntro = /^\/(#[\w\-]+)?$/.test(event.url);
        if (this.showIntro) {
          this.renderer.addClass(this.htmlTag, 'full-height');
        } else {
          this.renderer.removeClass(this.htmlTag, 'full-height');
        }
      }
    });
  }

  ngAfterViewInit(): void {}

  onNavHeightChange(event) {}
}
