import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewChecked
} from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input() appName = 'Christophe BOULAIN';
  @Input() fixed = false;
  @Input() scrolling = false;

  container = false;
  device = null;
  navHeight = 50;

  constructor(
    private router: Router,
    private deviceDetector: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.device = this.deviceDetector.getDeviceInfo();
    if (this.device && this.device.browser === 'ie') {
      this.container = true;
    }
  }

  getNavClasses() {
    let classes = 'navbar navbar-expand-lg navbar-dark indigo';
    if (this.scrolling) {
      classes += ' scrolling-navbar';
    }
    if (this.fixed) {
      classes += ' fixed-top';
    }

    return classes;
  }

  isLinkActive(url: string) {
    return this.router.url === url;
  }
}
