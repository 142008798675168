import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener,
  ElementRef
} from '@angular/core';
import { Location } from '@angular/common';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  devCompetencies: any[] = [];
  sysCompetencies: any[] = [];
  competencies = {
    dev: [],
    sys: [],
    language: []
  };
  competenciesSubscription: Subscription;
  currentSection: string;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.competenciesSubscription = this.dataService.competenciesSubject.subscribe(
      (data: any[]) => {
        data.forEach((c) => {
          this.competencies[c.category].push(c);
        });
      }
    );
    this.dataService.emitCompetencies();
  }

  ngOnDestroy(): void {
    this.competenciesSubscription.unsubscribe();
  }

  onSectionChange(section) {
    this.router.navigate([], { fragment: section });
  }
}
