import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { MarkerData } from '../../models/markerData';
import { MapService } from '../../services/map.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-interactive-cv',
  templateUrl: './interactive-cv.component.html',
  styleUrls: ['./interactive-cv.component.scss']
})
export class InteractiveCvComponent implements OnInit, AfterViewInit {
  selectedMarkerData: MarkerData;
  @ViewChild('sideDiv') sideBarEl: ElementRef;

  constructor(
    private mapService: MapService,
    private dataService: DataService
  ) {}

  @HostListener('window:resize', ['$event'])
  onresize(event): void {
    this.mapService.centerMap();
  }
  ngOnInit() {
    this.dataService.emitMarkers();
  }

  ngAfterViewInit() {}
  onSelectMarker(markerData: MarkerData) {
    this.selectedMarkerData = markerData;
    this.mapService.centerMap();
    if (this.sideBarEl) {
      this.sideBarEl.nativeElement.scrollTop = 0;
    }
  }

  onCloseInfoWindow() {
    this.selectedMarkerData = null;
  }
}
