import { MapService } from './services/map.service';
import { DataService } from './services/data.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NguiMapModule } from '@ngui/map';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MapComponent } from './pages/interactive-cv/map/map.component';
import { InteractiveCvComponent } from './pages/interactive-cv/interactive-cv.component';
import { NavComponent } from './header/nav.component';
import { CvDetailsComponent } from './pages/interactive-cv/cv-details/cv-details.component';
import { HomeComponent } from './pages/home/home.component';
import { IntroComponent } from './header/intro.component';
import { CompetenciesComponent } from './pages/home/competencies/competencies.component';
import { AboutComponent } from './pages/home/about/about.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { ContactComponent } from './pages/home/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';

const ROUTES: Routes = [
  { path: 'cv', component: InteractiveCvComponent },
  { path: 'cv/details/:id', component: CvDetailsComponent },
  { path: '', component: HomeComponent, pathMatch: 'full' }
];
@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    InteractiveCvComponent,
    NavComponent,
    IntroComponent,
    CvDetailsComponent,
    HomeComponent,
    CompetenciesComponent,
    AboutComponent,
    ScrollSpyDirective,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    NguiMapModule.forRoot({
      apiUrl:
        'https://maps.google.com/maps/api/js?language=fr&region=FR&key=AIzaSyA0wJQTlUsBXmH2u_45AHj0iAPNulR03Sg'
    }),
    RouterModule.forRoot(ROUTES, { useHash: false }),
    ScrollToModule.forRoot(),
    RoundProgressModule,
    MarkdownModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot()
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [MapService, DataService],
  bootstrap: [AppComponent]
})
export class AppModule {}
